import {
    APPROVED_AUDITORS,
    AUDITOR_POOL_CERTIFICATIONS,
    AUDITOR_POOL_PORTFOLIOS,
    GET_ALL_EMPLOYEES,
    GET_ALL_MERCHANT_USERS,
    MODULE_GUIDE,
    RISK_CURRENT_PERMISSION,
    RISK_PERMISSION,
    RISK_SELF_PORTAL_EMAIL,
    RISK_SELF_PORTAL_TOKEN,
    RISK_SELF_SERVICE,
    USER_INFO,
    GET_ADDONS,
    GET_SMART_INTEL,
    PEOPLE_SUMMARY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                user_info: action?.payload,
            };
        case APPROVED_AUDITORS:
            return {
                ...state,
                approved_auditors: action?.payload,
            };
        case AUDITOR_POOL_CERTIFICATIONS:
            return {
                ...state,
                auditor_pool_certifications: action?.payload,
            };
        case AUDITOR_POOL_PORTFOLIOS:
            return {
                ...state,
                auditor_pool_portfolio: action?.payload,
            };
        case MODULE_GUIDE:
            return {
                ...state,
                module: action?.payload,
            };
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                all_employees: action?.payload,
            };
        case GET_ALL_MERCHANT_USERS:
            return {
                ...state,
                all_merchant_users: action.payload,
            };
        case RISK_SELF_PORTAL_EMAIL:
            return {
                ...state,
                risk_self_portal_email: action.payload,
            };
        case RISK_SELF_PORTAL_TOKEN:
            return {
                ...state,
                risk_self_portal_token: action.payload,
            };
        case RISK_PERMISSION:
            return {
                ...state,
                risk_permission: action.payload,
            };
        case RISK_CURRENT_PERMISSION:
            return {
                ...state,
                risk_current_permission: action.payload,
            };
        case RISK_SELF_SERVICE:
            return {
                ...state,
                risk_self_service: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        case GET_ADDONS:
            return {
                ...state,
                all_addons: action.payload,
            };
        case GET_SMART_INTEL:
            return {
                ...state,
                all_smart_intel: action.payload,
            };
        case PEOPLE_SUMMARY:
            return {
                ...state,
                people_summary: action.payload,
            };
        default:
            return state;
    }
};
