import { validation } from 'validate';

export const validateForm = (values, required) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
        const valid = validation(values[key], 'Field', required[key]);
        if (!valid.isValid) {
            errors[key] = valid.errorMessage;
        }
    });
    return errors;
};

export const ExceptionValidation = (values) => {
    const errors = {};
    const reason = validation(values.reason, 'Reason is Required', true);

    if (!reason.isValid) {
        errors.reason = reason.errorMessage;
    } else {
        return null;
    }

    return errors;
};

export const assignTaskValidation = (values) => {
    const errors = {};

    if (!values?.employees?.length) {
        errors.employees = 'User is Required';
    }

    return errors;
};

export const reminderValidation = (values) => {
    const errors = {};
    const schedule_dateValidate = validation(values.schedule_date, 'Reminder', true);

    if (!schedule_dateValidate.isValid) {
        errors.schedule_date = schedule_dateValidate.errorMessage;
    }

    return errors;
};

export const documentValidation = (values) => {
    const errors = {};
    const approverValidate = validation(values.approver, 'Approver', true);

    if (!approverValidate.isValid) {
        errors.approver = approverValidate.errorMessage;
    }

    return errors;
};
