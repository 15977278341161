import { ExpandMore } from '@mui/icons-material';
import { MenuItem, Radio, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppFormControl from './forms/AppFormControl';

const AppSelectInput = ({
    label,
    labelStyle,
    name,
    options,
    value,
    error,
    multiple,
    disabled,
    loading,
    // toolTip,
    // tips,
    type,
    defaultValue,
    sx = {},
    MenuProps = {},
    onClick,
    noDataLabel,
    custom,
    ...restProps
}) => {
    const theme = useTheme();

    const newValue =
        typeof value === 'string' || typeof value === 'number'
            ? multiple
                ? value?.split(',')
                : value?.toString()?.trim()
            : value?.map((item) => item.toString().trim());

    return (
        <AppFormControl
            name={name}
            label={
                <>
                    {label}
                    {/* {toolTip && <FormTooltip placement="top" text={tips} />} */}
                </>
            }
            error={error}
            disabled={disabled}
            labelStyle={labelStyle ? labelStyle : { color: '#64748B', fontWeight: 500, fontSize: '13px' }}
        >
            <Select
                value={newValue}
                displayEmpty
                IconComponent={ExpandMore}
                disabled={disabled}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    height: 36,
                    fontSize: '14px',
                    border: '1px solid',
                    lineHeight: 0,
                    fontWeight: 400,
                    borderColor: error ? theme.palette.error[700] : '#E2E8F0',
                    color: (multiple ? value.length : value) ? '#64748B' : theme.palette.gray[400],
                    '&.Mui-disabled': { borderColor: theme.palette.gray[100] },
                    '& fieldset': { border: '0 !important' },

                    paddingTop: '0',
                    paddingBottom: '0',
                    ...sx,
                }}
                name={name}
                multiple={multiple}
                onClick={onClick}
                {...restProps}
                MenuProps={{
                    PaperProps: {
                        sx: { maxHeight: 250, maxWidth: '500px' },
                    },
                    ...MenuProps,
                }}
            >
                <MenuItem sx={{ color: '#94a3b8', fontSize: '14px' }} value="">
                    {defaultValue}
                </MenuItem>
                {!options?.length && (
                    <MenuItem
                        disabled
                        sx={{
                            color: '#64748B',
                            fontSize: '14px',
                            border: '1px solid #F1F5F9',
                        }}
                        value=""
                    >
                        {noDataLabel || defaultValue}
                    </MenuItem>
                )}
                {!loading ? (
                    options?.map((option) => {
                        const isString = typeof option === 'string';
                        const value = isString ? option?.trim() : option?.value?.toString()?.trim();
                        return (
                            <MenuItem
                                value={value}
                                sx={{
                                    color: '#64748B',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize: '14px',
                                    height: '44px',
                                    borderTop: '1px solid #F1F5F9',
                                    borderLeft: '1px solid #F1F5F9',
                                    borderRight: '1px solid #F1F5F9',
                                    borderBottom: '0.5px solid #F1F5F9',
                                    fontWeight: 500,

                                    '&.Mui-selected': {
                                        backgroundColor: '#0E2C66',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#0E2C66',
                                            color: '#ffffff',
                                        },
                                    },
                                }}
                                key={value}
                            >
                                {type === 'checkbox' && (
                                    <Radio
                                        checked={value === newValue}
                                        value="a"
                                        name="radio-buttons"
                                        sx={{ height: '30px' }}
                                        size="small"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                )}
                                {isString ? option : option.name}
                            </MenuItem>
                        );
                    })
                ) : (
                    <MenuItem disabled sx={{ color: theme.palette.gray[400], fontSize: '14px' }} value="">
                        Loading.......
                    </MenuItem>
                )}
                {custom &&
                    custom?.map((item) => {
                        return (
                            <MenuItem
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    paddingTop: '0',
                                    paddingBottom: '0',
                                }}
                                value={item.value}
                            >
                                {item.name}
                            </MenuItem>
                        );
                    })}
            </Select>
        </AppFormControl>
    );
};

export default AppSelectInput;
