import { ArrowBackIosRounded } from '@mui/icons-material';
import { Stack, Tooltip, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import AppDrawer from 'components/new_components/AppDrawer';
import React, { useMemo } from 'react';
import { ReactComponent as ThumbsUpIcon } from 'assets/img/thumbs-up.svg';
import { ReactComponent as ThumbsDownIcon } from 'assets/img/thumb-down.svg';
import { ArrowRight, Loader2 } from 'lucide-react';
import { ReactComponent as CalendarIcon } from 'assets/img/calendar2.svg';
import { ReactComponent as ViflyIcon } from 'assets/img/vifly2.svg';
// import { GetSmartComplianceAIRecommendations } from 'store/actions/dashboardActions';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ComplianceHealthSuggestions = (props) => {
    const { open, handleClose, smartComplianceReport, loadingState } = props;
    const theme = useTheme();
    const ai_recommendations = useSelector((state) => state?.dashboardReducers?.smart_compliance_ai_recommendations);
    // const dispatch = useDispatch();

    const frameworkKeys = ['pci dss', 'iso', 'ndpr', 'soc 2', 'kdpa', 'gdpa', 'pcidss', 'sslc'];
    const scanKeys = ['scan', 'penetration tests', 'infrastructures'];

    const report = useMemo(() => {
        if (smartComplianceReport && typeof smartComplianceReport === 'object') {
            const data = smartComplianceReport?.[0]?.scans
                ? Object.entries(smartComplianceReport?.[0]?.scans)?.map((el) => el?.[1])
                : [];
            let scans = [];
            let others = [];
            let frameworks = [];
            let agents = [];
            let employees = [];

            data.forEach((item) => {
                const issue = item?.issue?.toLowerCase();
                if (scanKeys.some((el) => issue?.includes(el)) && !issue?.includes('computer scans')) {
                    scans.push(item);
                } else if (issue?.includes('employee') || issue?.includes('computer scans')) {
                    employees.push(item);
                } else if (issue?.includes('agent')) {
                    agents.push(item);
                } else if (frameworkKeys.some((el) => issue?.includes(el))) {
                    frameworks.push(item);
                } else {
                    others.push(item);
                }
            });

            return { scans, frameworks, employees, agents, others };
        }

        return {};
    }, [smartComplianceReport]);

    // const getSmartComplianceAIRecommendations = async () => {
    //     const res = await dispatch(GetSmartComplianceAIRecommendations());

    //     if (!res?.success) {
    //         toast.error('Could not get AI recommendations');
    //     }
    // };

    const getTagType = (scan) => {
        const key = scan?.toLowerCase();
        if (scanKeys.some((el) => key.includes(el)) && !key.includes('computer scans')) {
            return ['passing', 'failing'];
        } else if (key?.includes('computer scans') || key?.includes('infrastructure agent')) {
            return ['scanned', 'not scanned'];
        } else if (key?.includes('agent')) {
            return ['computer installed', 'not installed'];
        } else if (key?.includes('vendor')) {
            return ['responded', 'not responded'];
        } else if (key?.includes('background check')) {
            return ['checked', 'not checked'];
        } else if (
            [
                'PCI DSS',
                'NDPR',
                'ISO 27001',
                'SOC 2',
                'BLOCKCHAIN',
                'ISO 22301',
                'PCIDSS 4',
                'PCI DSS 4.0',
                'KDPA',
                'GDPA',
                'SSLC',
                'ISO 9001',
            ]?.includes(scan)
        ) {
            return ['ready', 'need attention'];
        } else {
            return ['-', '-'];
        }
    };

    const getRecommendation = (scan) => {
        return ai_recommendations?.find((el) => el?.id === scan)?.Recommendation;
    };

    // useEffect(() => {
    //     getSmartComplianceAIRecommendations();
    // }, [open]);

    function getPercentTag(percentage) {
        return percentage <= 40 ? 'high' : percentage <= 70 ? 'medium' : 'low';
    }
    return (
        <AppDrawer
            open={open}
            icon={<ArrowBackIosRounded sx={{ color: '#46464A', fontSize: '18px' }} />}
            handleClose={handleClose}
            title={
                <div>
                    <p className="text-[16px] font-bold text-[#202D66]">Compliance health summary</p>
                </div>
            }
            closeicon={
                <Stack flexDirection="row" gap={0.5} alignItems="center" justifyContent="flex-end">
                    <p className="text-xs font-light text-[#64748B]">Powered by</p>
                    <ViflyIcon className="" />
                </Stack>
            }
            sx={{ maxWidth: '500px' }}
        >
            {loadingState ? (
                <div className="flex justify-center items-center mt-[6rem]">
                    <Loader2 className="h-10 w-10 animate-spin" />
                </div>
            ) : (
                <Stack direction="column" sx={{ mt: 3, mx: 2, gap: 1.5 }}>
                    {smartComplianceReport?.[0]?.scans?.length ? (
                        <>
                            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={5}>
                                <div className="flex items-center justify-start gap-2.5">
                                    <CalendarIcon className="w-5 h-5 flex-shrink-0" />
                                    <p className="text-xs font-light text-gray-500">
                                        Last checked:{' '}
                                        <span className="break-all font-medium text-gray-500">
                                            {moment(smartComplianceReport?.[2]?.last_scanned).format(
                                                'MMM DD, YYYY. HH:MM:SS'
                                            )}
                                        </span>
                                    </p>
                                </div>

                                <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                    <div className="flex items-center gap-0">
                                        <div
                                            className={`z-[500] flex h-14 w-14 items-center justify-center rounded-full ${
                                                smartComplianceReport?.[2]?.total_percentage <= 40
                                                    ? 'bg-[#DE3730]'
                                                    : smartComplianceReport?.[2]?.total_percentage <= 70
                                                    ? 'bg-[#FDD865]'
                                                    : 'bg-[#55BE8B]'
                                            } shadow-[0_12px_22px_0_rgba(0,0,0,0.08)]`}
                                        >
                                            <p className="text-sm font-semibold text-white">
                                                {(Math.round(smartComplianceReport?.[2]?.total_percentage) || 0) + '%'}
                                            </p>
                                        </div>
                                        <div
                                            className={`-ml-2 flex h-7 w-32 items-center justify-center rounded-r-full ${
                                                smartComplianceReport?.[2]?.total_percentage <= 40
                                                    ? 'bg-[#FF5449]'
                                                    : smartComplianceReport?.[2]?.total_percentage <= 70
                                                    ? 'bg-[#FDD865]'
                                                    : 'bg-[#55BE8B]'
                                            }`}
                                        >
                                            <p className="text-sm -ml-2 font-semibold text-[#FFFFFF] capitalize">
                                                {getPercentTag(
                                                    Math.round(smartComplianceReport?.[2]?.total_percentage)
                                                )}{' '}
                                                Risk
                                            </p>
                                        </div>
                                    </div>
                                </Stack>
                            </Stack>

                            {Object.entries(report)?.map((scan, index) => {
                                const all_succeeded = scan[1]?.every((el) => !el.failed);
                                return (
                                    <React.Fragment key={index}>
                                        {scan?.[1].length ? (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        borderBlock: '1px solid #E2E8F0',
                                                        p: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 17,
                                                            color: theme.palette.primary[40],
                                                            fontWeight: 700,
                                                            textTransform: 'capitalize',
                                                            mb: 0,
                                                        }}
                                                        variant="h3"
                                                    >
                                                        {scan?.[0]}
                                                    </Typography>
                                                    <Stack
                                                        flexDirection="row"
                                                        gap={1}
                                                        alignItems="center"
                                                        justifyContent="flex-end"
                                                    >
                                                        {all_succeeded ? (
                                                            <ThumbsUpIcon className="text-md" />
                                                        ) : (
                                                            <ThumbsDownIcon className="text-md" />
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                                color: all_succeeded ? '#10B981' : '#B28C00',
                                                            }}
                                                        >
                                                            {all_succeeded ? 'All passed' : 'Needs work'}
                                                        </Typography>
                                                    </Stack>
                                                </Box>

                                                <Stack
                                                    flexDirection="row"
                                                    columnGap={1}
                                                    alignItems="start"
                                                    justifyContent="space-between"
                                                    flexWrap="wrap"
                                                    px={0.5}
                                                    py={1}
                                                    rowGap={3}
                                                >
                                                    {scan?.[1].map((el, key) => {
                                                        const isLastItemAndOdd =
                                                            scan[1].length === key + 1 && (key + 1) % 2 !== 0;
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: 2.5,
                                                                    alignItems: 'start',
                                                                    justifyContent: 'start',
                                                                    width:
                                                                        scan[0] === 'agents' || scan[0] === 'others'
                                                                            ? '100%'
                                                                            : isLastItemAndOdd
                                                                            ? '100%'
                                                                            : '47%',
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        width: '100%',
                                                                        alignItems: 'center',
                                                                        gap: '5px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontWeight: 700,
                                                                            color: '#202D66',
                                                                            fontSize: 13,
                                                                        }}
                                                                    >
                                                                        {el?.issue}
                                                                    </Typography>
                                                                    {el.failed ? (
                                                                        <Tooltip
                                                                            title={
                                                                                getRecommendation(el?.id) ||
                                                                                'No recommendations at the moment'
                                                                            }
                                                                            placement="top"
                                                                            arrow
                                                                        >
                                                                            <Stack
                                                                                flexDirection="row"
                                                                                gap={0.2}
                                                                                justifyContent="flex-end"
                                                                                alignItems="center"
                                                                                sx={{ cursor: 'pointer' }}
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: theme.palette
                                                                                            .primary[40],
                                                                                        fontSize: 11.5,
                                                                                        fontWeight: 600,
                                                                                    }}
                                                                                >
                                                                                    How to fix
                                                                                    {/* <ArrowRight className="text-[10px] text-[#395BA9] -rotate-45" /> */}
                                                                                </Typography>
                                                                                <ArrowRight className="w-[16px] h-[16px] text-[#395BA9] -rotate-45" />
                                                                            </Stack>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: 1.5,
                                                                        alignItems: 'start',
                                                                        justifyContent: 'start',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <Cylinder
                                                                        low={el?.passed}
                                                                        medium={0}
                                                                        high={el?.failed}
                                                                    />
                                                                    <Stack
                                                                        flexDirection="row"
                                                                        justifyContent="start"
                                                                        alignItems="center"
                                                                        gap={2}
                                                                    >
                                                                        <Stack
                                                                            flexDirection="row"
                                                                            justifyContent="start"
                                                                            alignItems="center"
                                                                            gap={0.5}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    width: '8px',
                                                                                    height: '8px',
                                                                                    bgcolor: '#55BE8B',
                                                                                    borderRadius: '100%',
                                                                                    flexShrink: 0,
                                                                                }}
                                                                            ></Box>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 11,
                                                                                    color: theme.palette.gray[600],
                                                                                }}
                                                                            >
                                                                                <span className="font-semibold">
                                                                                    {el?.passed}
                                                                                </span>{' '}
                                                                                {getTagType(el?.issue)[0]}
                                                                            </Typography>
                                                                        </Stack>

                                                                        <Stack
                                                                            flexDirection="row"
                                                                            justifyContent="start"
                                                                            alignItems="center"
                                                                            gap={0.5}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    width: '8px',
                                                                                    height: '8px',
                                                                                    bgcolor: '#FF5449',
                                                                                    borderRadius: '100%',
                                                                                    flexShrink: 0,
                                                                                }}
                                                                            ></Box>
                                                                            <Typography sx={{ fontSize: 11 }}>
                                                                                <span className="font-semibold">
                                                                                    {el?.failed}
                                                                                </span>{' '}
                                                                                {getTagType(el?.issue)[1]}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Stack>
                                            </>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        </>
                    ) : (
                        <EmptyComponent />
                    )}
                </Stack>
            )}
        </AppDrawer>
    );
};

export const Cylinder = ({ high = 0, medium = 0, low = 0 }) => {
    const total = high + medium + low;

    // const highPercentage = (high / total) * 100;
    const mediumPercentage = (medium / total) * 100;
    const lowPercentage = (low / total) * 100;

    return (
        <Box
            sx={{
                width: '100%',
                height: '8px',
                backgroundColor: '#E2E8F0',
                borderRadius: '30px',
                overflow: 'hidden',
                display: 'flex',
                position: 'relative',
            }}
        >
            {low > 0 && (
                <Box
                    sx={{
                        width: `${lowPercentage}%`,
                        backgroundColor: '#55BE8B',
                        borderRadius: '0 30px 30px 0',
                        position: 'absolute',
                        height: '100%',
                        zIndex: 90,
                    }}
                />
            )}

            {medium > 0 && (
                <Box
                    sx={{
                        width: `${lowPercentage + mediumPercentage}%`,
                        backgroundColor: '#FFDF91',
                        borderRadius: '0 30px 30px 0',
                        position: 'absolute',
                        height: '100%',
                        zIndex: 60,
                    }}
                />
            )}

            {high > 0 && (
                <Box
                    sx={{
                        width: `100%`,
                        backgroundColor: '#FF5449',
                        position: 'absolute',
                        height: '100%',
                        zIndex: 30,
                    }}
                />
            )}
        </Box>
    );
};

export default ComplianceHealthSuggestions;
