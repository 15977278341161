import axios from 'axios';
import axiosInstance from '../../utils/https';
import * as types from '../constants';
import { capitalizeFirstLetter } from 'utils';

export const GetOverview = (matrix) => async (dispatch) => {
    // const response = await https().get(`/scans/risk_overview?matrix=${matrix}`);
    try {
        const res = await axiosInstance().get(`/scans/risk_overview?matrix=${matrix}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.OVERVIEW, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetAllThreats = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/threat`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_ASSESSMENT_THREATS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllVulnerabilities = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/vulnerabilities`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_ASSESSMENT_VULNERABILITIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_category`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_ASSESSMENT_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllThreatCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/threat_categories`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_ASSESSMENT_THREAT_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddNewThreat = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = {
        ...credentials,
        merchant,
    };
    try {
        const res = await axiosInstance().post(`/scans/threats/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllThreats());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllTemplate = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_template/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_ASSESSMENT_TEMPLATES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddNewTemplate = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = {
        ...credentials,
        merchant,
    };
    try {
        const res = await axiosInstance().post(`/scans/risk_template/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTemplate());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateTemplate = (credentials, id) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = {
        ...credentials,
        merchant,
    };
    try {
        const res = await axiosInstance().patch(`/scans/risk_template/${id}/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTemplate());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteTemplate = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_template/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTemplate());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddNewCIA = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/scans/cia_calculator/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
            data: res?.data?.risk_level,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAssetGroups = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/asset_group/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.MERCHANT_ASSET_GROUP,
            payload: res?.data,
        });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllAssets = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/assets/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_MERCHANT_ASSETS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddNewVulnerability = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = {
        ...credentials,
        merchant,
    };
    try {
        const res = await axiosInstance().post(`/scans/vulnerabilities/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVulnerabilities());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllRiskAssessment = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment/`, {
            params,
        });
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_MERCHANT_RISK_ASSESSMENT,
            payload: res?.data || res,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RunNewRiskAssessment = (credentials, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/risk_assessment/`, credentials);
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RunMultipleNewRiskAssessment = (credentials, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/risk_assessment/bulk-create/`, credentials);
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantUsers = () => async (dispatch, getState) => {
    const company_id = getState()?.authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/user_mgt/merchant_users/?merchant_id=${company_id}`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.GET_ALL_MERCHANT_USERS, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetRiskManagementSettings = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_mgt_settings/`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.RISK_MANAGEMENT_SETTINGS, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetRiskMitagations = (credentials) => async () => {
    try {
        const res = await axios.post(`https://bot.smartcomplyapp.com/bot/risk_assessment_ai/`, credentials);
        return { success: true, message: res?.message, data: res?.data };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetRiskSettings = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/risk_mgt_settings/`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.GET_RISK_SETTINGS, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteRiskAssessment = (id, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_assessment/${id}`);
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteMultipleRiskAssessment = (ids, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/scans/risk_assessment/bulk-delete/`, { data: { ids } });
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateRiskiskAssessment = (credentails, id, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/risk_assessment/${id}/`, credentails);
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err.message
                ? `${capitalizeFirstLetter(Object.keys(err.message)?.[0])?.replaceAll('_', ' ')}: ${
                      Object.values(err.message)?.[0]?.[0]
                  }`
                : '',
        };
    }
};

export const UpdateMultipleRiskAssessment = (credentails, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/risk_assessment/bulk-update/`, credentails);
        // if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRiskAssessment(params));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetRiskOwners = () => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`scans/risk_owners/?merchant_id=${merchant}&type=solution_owner`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.GET_ALL_RISK_OWNER, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAssetOwners = () => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`scans/risk_owners/?merchant_id=${merchant}&type=report_owner`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.GET_ALL_ASSET_OWNER, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CbnReport = () => async (_, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`scans/spool_risk_assessment/?merchant_id=${merchant}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        console.log({ res });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SmartcomplyReport = (matrix) => async (_, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`scans/spool_risk_assessment/?merchant_id=${merchant}&matrix=${matrix}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        console.log({ res });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SetRiskDefaultSettings = (credentails, riskDataID) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/scans/risk_mgt_settings/${riskDataID}/`, {
            ...credentails,
            merchant,
        });
        dispatch(GetRiskManagementSettings());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateCurrentUserRiskManagementData = (credentails) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/scans/risk_mgt_settings/`, { ...credentails });
        dispatch(GetRiskManagementSettings());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const updateCurrentUserRiskManagementData = (credentails, riskDataID) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/scans/risk_mgt_settings/${riskDataID}`, { ...credentails });
        dispatch(GetRiskManagementSettings());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RunAssmentAction = (data) => async (dispatch) => {
    dispatch({ type: types.OPEN_RISK_ASSESSMENT, payload: data });
};

export const OpenAutoRiskAction = (data) => async (dispatch) => {
    dispatch({ type: types.OPEN_AUTO_RISK_ASSESSMENT, payload: data });
};

export const OpenAutoRiskMitigation = (data) => async (dispatch) => {
    dispatch({ type: types.OPEN_AUTO_RISK_MITIGATION, payload: data });
};

export const OpenAutoRiskExcalation = (data) => async (dispatch) => {
    dispatch({ type: types.OPEN_AUTO_RISK_EXCALATION, payload: data });
};

export const OpenAutoRiskApproval = (data) => async (dispatch) => {
    dispatch({ type: types.OPEN_AUTO_RISK_APPROVAL, payload: data });
};

export const AddAssetRisk = (data) => async (dispatch) => {
    dispatch({ type: types.ADD_ASSET_RISK, payload: data });
};

export const UploadRiskAddessment = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    credentials.append('merchant_id', merchant);
    try {
        const res = await axiosInstance().post(`/scans/riskassessment_upload/`, credentials);
        dispatch(GetAllRiskAssessment());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
