import axios from 'axios';
import { baseUrl } from '../config';
import { LOGGER } from '../utils';
import { toast } from 'react-toastify';
// import store from '../store';
import { configuredStore } from '../index';
import { LogoutUser } from 'store/actions/authActions';

const baseURL = baseUrl;
// export const configuredStore = store();

const logout = async () => {
    await LogoutUser();
    window.location.href = '/auth/login';
};

export default () => {
    // const accessToken = getUserToken('token');
    const accessToken = configuredStore?.store?.getState().authReducers?.user_details?.key;
    const headers = {};

    if (accessToken) {
        headers.Authorization = `Token ${accessToken}`;
    }

    const axiosInstance = axios.create({
        baseURL,
        headers,
        timeout: 60000,
    });

    const requestHandler = (request) => {
        // Token will be dynamic so we can use any app-specific way to always
        // fetch the new token before making the call
        if (accessToken) request.headers.Authorization = `Token ${accessToken}`;
        return request;
    };

    const errorHandler = (error) => {
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
    };

    axiosInstance.interceptors.request.use(
        (request) => {
            return requestHandler(request);
        },
        (error) => {
            errorHandler(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.status === 204) {
                return { status: 'success' };
            }
            return response.data;
        },
        async (err) => {
            const statusCode = err.response.status;
            if (statusCode === 400) {
                // credentials error
                if (err?.response?.data?.message === 'Paywall' || err?.response?.data?.data === 'Paywall') {
                    window.location = '/paywall';
                } else if (err?.response?.data?.message === 'Upsell' || err?.response?.data?.data === 'Upsell') {
                    window.location = '/upsell';
                } else if (err?.response?.data?.message === 'Access denied: The request is older than 4 hours') {
                    window.location = '/risk-services/login/';
                } else {
                    // credentials error
                    return Promise.reject({
                        status: 400,
                        message:
                            err?.response?.data?.message === 'failed'
                                ? err?.response?.data?.data
                                : err?.response?.data?.message || err?.response?.data,
                    });
                }
            } else if (statusCode === 401 || statusCode === 403) {
                toast.error('Your session has expired, log in again!');
                setTimeout(() => {
                    logout();
                }, 1000);
                // return a promise (rejection)
                return Promise.reject({ status: statusCode });
            } else {
                // Something went wrong with the server
                LOGGER(err.response.statusText);
                // return promise
                return Promise.reject({
                    status: err.response.statusCode,
                    message: err.response.statusText,
                });
            }
        }
    );

    return axiosInstance;
};
// else if (statusCode === 401 || statusCode === 403) {
//     toast.error('Your session has expired, log in again!');
//     setTimeout(() => {
//         window.location = '/auth/login';
//         endSession(getUserToken('token'), getType('type'), getType('role'));
//     }, 1000);
//     // return a promise (rejection)
//     return Promise.reject({ status: statusCode });
// } else if (statusCode === 404) {
//     // eNdpoint not found
//     LOGGER(`Endpoint not existent: `, err.response.config.baseURL);
//     return Promise.reject({
//         status: 404,
//         message: 'Resource not found',
//     });
// }
