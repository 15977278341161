import { Box, Button, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { connect } from 'react-redux';
import { MoreVert } from '@mui/icons-material';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { CloseOutlined } from '@ant-design/icons';

const Evidences = (props) => {
    const {
        control,
        setDoc_id,
        all_merchant_evidences,
        setControl,
        req_id,
        openEvidenceHistoryModal,
        openExemptEvidenceModal,
        evidence_type,
        setControlModal,
    } = props;

    const [anchor, setAnchor] = useState(null);
    const [showDescription, setShowDescription] = useState(false);

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === control?.id);
    }, [control, all_merchant_evidences]);

    const filtered_doc_numb = (
        selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`])
            ? Object?.values(selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`]))
            : []
    )?.reduce((prev, current) => {
        const reduced_items = prev + current?.length;
        return reduced_items;
    }, 0);

    const expected_doc_number = (evidence_type === 'soc2' ? control?.segments?.slice(0, 1) : control?.segments)
        ?.map((segment, index) => segment?.[`segment_${index + 1}`])
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);
    return (
        <Box key={control?.id}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '50%',
                        }}
                    >
                        <img src={FileUnCheck} alt="fileunckeck" />{' '}
                        <div>
                            <Typography
                                sx={{
                                    ml: 2,
                                    color: '#395BA9',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    mb: 0.5,
                                }}
                            >
                                {control?.soc2_title ||
                                    control?.lifecycle_title ||
                                    control?.quality_mgt_title ||
                                    control?.card4_title}
                            </Typography>
                            {control?.soc2_description || control?.quality_mgt_description ? (
                                <Typography
                                    sx={{
                                        ml: 2,
                                        color: '#395BA9',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setShowDescription(true)}
                                >
                                    View detailed description
                                </Typography>
                            ) : null}
                            <ViewDescription
                                description={control?.soc2_description || control?.quality_mgt_description}
                                open={showDescription}
                                handleClose={() => setShowDescription(false)}
                                dateCreated={control?.date_created}
                            />
                        </div>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {selectedEvidence ? (
                            <>
                                {filtered_doc_numb > expected_doc_number ? (
                                    <Button
                                        sx={{
                                            textTransform: 'inherit',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            backgroundColor: '#F8FAFC',
                                            border: '1px solid #E2E8F0',
                                            px: 1,
                                            color:
                                                filtered_doc_numb === expected_doc_number ||
                                                filtered_doc_numb > expected_doc_number
                                                    ? '#395BA9'
                                                    : '#64748B',
                                            ml: 1,
                                            '&:hover': {
                                                backgroundColor: '#F8FAFC !important',
                                                border: '1px solid #E2E8F0',
                                            },
                                        }}
                                        onClick={() => {
                                            setDoc_id(control?.id);
                                            setControl(req_id);
                                            setControlModal(control);
                                            openEvidenceHistoryModal();
                                        }}
                                        variant="outlined"
                                        startIcon={
                                            <FiberManualRecordIcon
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    color:
                                                        filtered_doc_numb === expected_doc_number ||
                                                        filtered_doc_numb > expected_doc_number
                                                            ? '#55BE8B'
                                                            : '#64748B',
                                                }}
                                            />
                                        }
                                    >
                                        Update Evidence ({filtered_doc_numb})
                                    </Button>
                                ) : (
                                    <Button
                                        sx={{
                                            textTransform: 'inherit',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            px: 1,
                                            backgroundColor: '#F8FAFC',
                                            border: '1px solid #E2E8F0',
                                            color:
                                                filtered_doc_numb === expected_doc_number ||
                                                filtered_doc_numb > expected_doc_number
                                                    ? '#395BA9'
                                                    : '#64748B',
                                            ml: 1,
                                            '&:hover': {
                                                backgroundColor: '#F8FAFC !important',
                                                border: '1px solid #E2E8F0',
                                            },
                                        }}
                                        onClick={() => {
                                            setDoc_id(control?.id);
                                            setControl(req_id);
                                            setControlModal(control);
                                            openEvidenceHistoryModal();
                                        }}
                                        variant="outlined"
                                        startIcon={
                                            <FiberManualRecordIcon
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    color:
                                                        filtered_doc_numb === expected_doc_number ||
                                                        filtered_doc_numb > expected_doc_number
                                                            ? '#55BE8B'
                                                            : '#64748B',
                                                }}
                                            />
                                        }
                                    >
                                        {filtered_doc_numb === expected_doc_number ||
                                        filtered_doc_numb > expected_doc_number
                                            ? 'Update Evidence'
                                            : 'Upload Evidence'}{' '}
                                        ({filtered_doc_numb} / {expected_doc_number})
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                sx={{
                                    textTransform: 'inherit',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    color: '#64748B',
                                    backgroundColor: '#F8FAFC',
                                    border: '1px solid #E2E8F0',
                                    ml: 1,
                                    '&:hover': {
                                        backgroundColor: '#F8FAFC',
                                        border: '1px solid #E2E8F0',
                                    },
                                }}
                                onClick={() => {
                                    if (!selectedEvidence) {
                                        setDoc_id(control?.id);
                                        setControl(req_id);
                                        setControlModal(control);
                                        openEvidenceHistoryModal();
                                    }
                                }}
                                variant="outlined"
                                startIcon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            color: '#64748B',
                                        }}
                                    />
                                }
                            >
                                Upload Evidence
                            </Button>
                        )}
                        <>
                            <Box sx={{ ml: 2, cursor: 'pointer' }} onClick={openMenu}>
                                <MoreVert />
                            </Box>
                            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                                <MenuItem
                                    onClick={() => {
                                        openExemptEvidenceModal(control);
                                        closeMenu();
                                    }}
                                >
                                    <DoDisturbAltIcon sx={{ color: 'red' }} />{' '}
                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            color: 'red',
                                            ml: 0.5,
                                        }}
                                    >
                                        Not Applicable
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    </Box>
                </Box>
                <Divider sx={{ color: '#F1F5F9', mx: '-2rem' }} />
            </Box>
        </Box>
    );
};

export const ViewDescription = (props) => {
    const { open, handleClose, description } = props;

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={450}
            padding={'0px'}
            title={<h3 className="text-[#202D66]">Detailed description of evidence</h3>}
            dialogActionsStyle={{ px: 0 }}
            headerAction={
                <div
                    onClick={handleClose}
                    className="cursor-pointer bg-white rounded-full border border-[#E2E8F0] w-6 h-6 flex items-center justify-center"
                >
                    <CloseOutlined className="text-[#334155] text-xs" />
                </div>
            }
            actions={
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: -1, gap: 1 }}>
                    <div className="w-full px-6 pb-4 max-h-[300px] overflow-y-auto">
                        <Typography sx={{ textAlign: 'left', color: '#64748B', fontSize: 15, wordBreak: 'break-word' }}>
                            {description}
                        </Typography>
                    </div>
                    <div className="h-20 px-6 bg-[#F8FAFC] w-full flex justify-end items-center">
                        <Button
                            variant="text"
                            sx={{
                                backgroundColor: '#FFFFFF !important',
                                color: '#334155 !important',
                                textTransform: 'inherit !Important',
                                border: '1px solid #E2E8F0 !important',
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF !important',
                                },
                                height: 'fit-content',
                            }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </Stack>
            }
        ></AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
        evidences_metrics: state?.complianceReducers?.all_evidences_metrics,
    };
};
export default connect(mapStateToProps, {})(Evidences);
