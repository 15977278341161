import React, { useEffect, useMemo } from 'react';

import { Box, Badge } from '@mui/material';
import Notification from 'assets/img/notification.svg';
//redux
import { connect } from 'react-redux';
import { ReadAllMerchantNotifications } from 'store/actions/notificationActions';

const NotificationDropdownTrigger = ({
    openNotificationModal,
    notificationModalOpen,
    all_merchant_notifications,
    ReadAllMerchantNotifications,
}) => {
    // memo
    const newMerchantNotificationAvailable = useMemo(
        () =>
            all_merchant_notifications &&
            all_merchant_notifications?.length &&
            all_merchant_notifications?.some((notification) => !notification?.read_status),
        [all_merchant_notifications]
    );

    useEffect(() => {
        if (notificationModalOpen && newMerchantNotificationAvailable) {
            ReadAllMerchantNotifications();
        }
    }, [notificationModalOpen, newMerchantNotificationAvailable]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mx: { xs: 0, md: 1.5 },
            }}
        >
            <div onClick={openNotificationModal} className="cursor-pointer">
                <Badge
                    color="error"
                    badgeContent=""
                    variant="dot"
                    overlap="circular"
                    invisible={!newMerchantNotificationAvailable}
                >
                    <img src={Notification} alt="Notification" />
                </Badge>
            </div>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_notifications: state?.notificationReducers?.all_merchant_notifications,
    };
};
export default connect(mapStateToProps, { ReadAllMerchantNotifications })(NotificationDropdownTrigger);
