import { Badge, Box, Button, Container, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// core components
import AppStyledTab from 'components/new_components/tabs/AppStyledTab';
import AppTabsBar from 'components/new_components/tabs/AppTabsBar';

//translation
import { useTranslation } from 'react-i18next';
// import AppTab from 'components/new_components/tabs/AppTab';
import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';
import { useSelector } from 'react-redux';
// import AppStyledTabNew from 'components/new_components/tabs/AppStyledTabNew';

import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import SmartIntel from 'components/new_components/SmartIntel';
import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';

const availableStandards = ['pcidss', 'ndpr', 'iso27001', 'is022301', 'card4', 'kdpa', 'gdpa'];

const ComplianceLayout = (props) => {
    const theme = useTheme();
    const {
        children,
        onTabChange,
        tab,
        standard,
        openTasksModal,
        openDataRoomModal,
        // standards,
        ref1,
        ref2,
        ref3,
    } = props;

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const keywords = merchant_info?.intel_keywords?.['Compliance'];
    const { handleOpen } = useSmartIntelContext();
    const switch_on = merchant_info?.smart_intel;

    //translation
    const { t } = useTranslation('compliance');

    const handleTabChange = (_, tab) => {
        onTabChange(tab);
    };
    const tabs = [
        {
            index: 0,
            label: t('documents'),
        },
        {
            index: 1,
            label: t('auditQuestion'),
        },
        {
            index: 2,
            label: t('exceptions'),
        },
    ];

    const compliance_intel =
        switch_on && all_smart_intel?.Compliance
            ? Object?.values(all_smart_intel?.Compliance)
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <SmartIntel>
            <Container
                maxWidth={false}
                elevation={0}
                sx={{
                    p: '0 !important',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    mb: 5,
                }}
            >
                {standard === 'blockchain' || standard === 'cii' ? (
                    <AppTabsBar
                        value={tab}
                        onChange={handleTabChange}
                        sx={{
                            borderBottom: '2px solid',
                            borderColor: theme.palette.gray[600] + '20',
                        }}
                    >
                        <AppStyledTab
                            label={standard === 'cii' ? 'CII & Risk Management Directives' : t('criteria')}
                            id={0}
                            ref={ref1}
                        />
                        <AppStyledTab label={t('exceptions')} id={1} ref={ref2} />
                    </AppTabsBar>
                ) : (
                    <AppTabsBarNew
                        currentTab={tab}
                        tabs={tabs}
                        onChange={onTabChange}
                        standard={standard}
                        ref1={ref1}
                        ref2={ref2}
                        ref3={ref3}
                        others={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 3,
                                }}
                            >
                                {standard === 'card4' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Badge variant="dot" invisible={false} color="error" sx={{ ml: 2 }}>
                                            <Button
                                                sx={{
                                                    border: 'none',
                                                    backgroundColor: '#F1F5F9',
                                                    color: '#002C72',
                                                    textTransform: 'inherit',
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        backgroundColor: '#F1F5F9',
                                                    },
                                                }}
                                                onClick={openDataRoomModal}
                                            >
                                                Additional Evidences
                                            </Button>
                                        </Badge>
                                    </Box>
                                )}

                                {availableStandards.includes(standard) &&
                                    (user_type !== 'auditor' && user_type !== 'merchant' ? (
                                        <Badge
                                            variant="dot"
                                            invisible={false}
                                            color="error"
                                            onClick={() => {
                                                openTasksModal();
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    border: 'none',
                                                    backgroundColor: '#E1E2EC',
                                                    color: '#002C72',
                                                    textTransform: 'inherit',
                                                    fontSize: '13px',
                                                    '&:hover': {
                                                        backgroundColor: '#E1E2EC',
                                                    },
                                                }}
                                            >
                                                Tasks for you
                                            </Button>
                                        </Badge>
                                    ) : null)}
                            </Box>
                        }
                    />
                )}
                {switch_on && compliance_intel?.length ? (
                    <div className=" mt-[1.5rem] mb-0 mx-4 transition ease-in-out duration-500">
                        <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                            <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                                <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                                <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                    {compliance_intel?.length}
                                </p>
                                <Carousel
                                    slides={compliance_intel}
                                    handleOpen={handleOpen}
                                    type="Compliance"
                                    data={all_smart_intel?.Compliance}
                                    containerStyles="page-slide !h-[24px]"
                                />
                            </div>
                            <img src={ArrowRight} alt="ArrowRight" />
                        </div>
                    </div>
                ) : null}
                <Stack px={{ flex: 1 }}>{children}</Stack>
            </Container>
        </SmartIntel>
    );
};

export default ComplianceLayout;
