import { validation } from 'validate';

export const companyValidation = (values) => {
    const errors = {};
    const name = validation(values.name, 'name', true);
    const address = validation(values.address, 'address', true);
    const phone = validation(values.phone_number, 'phone number', true);
    const description = validation(values.description, 'description', true);
    // const standards = validation(values.standards, 'standards', true);
    const dba = validation(values.dba, 'DBA', true);
    const mailingAddress = validation(values.mailing_address, 'Mailing Address', true);
    const companyWebsite = validation(values.website, 'Company Website', true);

    if (!name.isValid) {
        errors.name = name.errorMessage;
    }
    if (!address.isValid) {
        errors.address = address.errorMessage;
    }
    if (!phone.isValid) {
        errors.phone_number = phone.errorMessage;
    }
    if (!description.isValid) {
        errors.description = description.errorMessage;
    }
    // if (!standards.isValid) {
    //     errors.standards = standards.errorMessage;
    // }
    if (!dba.isValid) {
        errors.dba = dba.errorMessage;
    }
    if (!mailingAddress.isValid) {
        errors.mailing_address = mailingAddress.errorMessage;
    }
    if (!companyWebsite.isValid) {
        errors.website = companyWebsite.errorMessage;
    }
    return errors;
};
