import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AppAvatar from 'components/new_components/AppAvatar';
import CustomTooltip from 'components/new_components/CustomTooltip';

// redux
import { connect, useSelector } from 'react-redux';
import { GetDownloadLink } from 'store/actions/generalActions';
import UserAvatar from 'assets/img/userAvatar.svg';

const UserDropdownTrigger = (props) => {
    const { showDropdown, company_info } = props;
    const theme = useTheme();
    const [imageLink, setImageLink] = useState('');

    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    useEffect(() => {
        if (company_info?.company_img) {
            const user_type =
                user_info.user_type === 'implementer'
                    ? 'implementer'
                    : user_info.user_type === 'auditor'
                    ? 'auditor'
                    : 'merchant';

            // set loading icon on start of fetch request
            GetDownloadLink('user_mgt', user_type, user_info.organization.id)().then((res) => {
                if (res.success) {
                    setImageLink(res.data);
                } else {
                    setImageLink('');
                }
            });
        }
    }, [user_info?.picture, company_info?.company_img]);

    return (
        <Box
            component="button"
            onClick={showDropdown}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'inherit',
                color: theme.palette.primary[900],
            }}
        >
            <AppAvatar src={imageLink || UserAvatar} size={25} />
            <CustomTooltip title="More Options" placement="bottom">
                <ExpandMore color="primary" />
            </CustomTooltip>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        company_info: state?.generalReducers?.user_info?.organization,
        employee_details: state?.generalReducers?.user_info?.employee?.user_detail,
    };
};
export default connect(mapStateToProps, {})(UserDropdownTrigger);
