import React from 'react';
//core component
import { Typography, Stack } from '@mui/material';
import AppCustomSwitch from 'components/new_components/AppCustomSwitch';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BulbIcon } from 'assets/img/bulb (2).svg';

const AuthControlSwitch = (props) => {
    const { checked, onChange, mfa_type } = props;
    const { t } = useTranslation('accounts');

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ border: '1px solid #F0F0FA', background: '#F8FAFC', px: 2.5, py: 1, borderRadius: '10px' }}
        >
            <div className="flex items-start justify-start gap-3">
                <BulbIcon className="flex-shrink-0 w-7 h-7" />
                <Typography sx={{ pr: 4, fontSize: 15, fontWeight: 400 }}>{t('twoFactorAuth.switchText')}</Typography>
            </div>
            {mfa_type && <AppCustomSwitch checked={checked} onChange={onChange} />}
        </Stack>
    );
};

export default AuthControlSwitch;
