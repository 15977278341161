import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import FileQuestion from 'assets/img/file-question.svg';
// import FileDocument from 'assets/img/file_document.svg';
// import { useSelector } from 'react-redux';
import PCISummary from './PCISummary';
import NdprSummary from './NdprSummary';
import SOC2Summary from './SOC2Report';
import ISO27001Summary from './ISO27001Report';
import SSLCSummary from './SSLCReport';
import ISO22301Summary from './ISO22301Report';
import PCI4Summary from './PCI4.0Summary';
import ISO270012022Summary from './ISO270012022Report';
import ISO9001Summary from './ISO9001Report';
import GDPAReport from './GDPAReport';
import KDPAReport from './KDPAReport';
import ISO27017ISO27032Report from './ISO27017iSO27032';
import { useSelector } from 'react-redux';
import BLOCKCHAINSummary from './BLOCKCHAINReport';
import CIISummary from './CIIReport';

const ReportSummary1 = ({ standards, automated_scans, view }) => {
    const all_automated_scan_group = useSelector((state) => state?.automatedScansReducers?.all_automated_scan_group);
    const { all_automated_scan_summary, ciso_percentage_ciso_summary } = useSelector((state) => state?.CISOReducers);
    const scorecard = ciso_percentage_ciso_summary?.total_percentage?.toFixed(2);
    return (
        <div className="mt-[5rem] w-full xl:flex justify-between items-center gap-10" hidden={view === 'Report View'}>
            <div
                className="xl:w-[35%] flex justify-center items-center flex-col xl:mb-0 md:mb-[4rem]"
                hidden={view === 'Report View'}
            >
                <h6 className="text-[#2B3674] text-lg font-bold text-center">Compliance Readiness</h6>
                <p className="text-gray-500 text-xs font-normal text-center">
                    This measures your overall compliance readiness for all processes in place
                </p>
                <div className="relative h-[180px] w-full mt-5 flex justify-center items-center">
                    <div className="absolute top-0 xl:left-20 md:left-[40%]">
                        <div className="absolute w-[140px] h-[140px] rounded-full bg-[#E2E8F0]">
                            <div
                                className={`absolute rounded-full w-[91px] h-[91px] border-[76px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                                ${
                                    scorecard > 70
                                        ? 'border-y-[#55BE8B] border-x-[#55BE8B]'
                                        : scorecard > 69
                                        ? 'border-y-[#55BE8B] border-r-[#55BE8B]'
                                        : scorecard > 50
                                        ? 'border-t-[#F2C021] border-r-[#F2C021]'
                                        : scorecard > 49
                                        ? 'border-t-[#FF5449] border-r-[#FF5449]'
                                        : 'border-t-[#FF5449]'
                                }`}
                            >
                                <div className="bg-white rounded-full w-[91px] h-[91px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                    <p className="text-[#202D66] font-bold text-[34px]">{Math.round(scorecard) || 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-5">
                    <div className="flex items-center gap-2">
                        <div className="bg-[#FF5449] h-[16px] w-[16px] rounded-sm" />

                        <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap">0 - 49</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <div className="bg-[#F2C021] h-[16px] w-[16px] rounded-sm" />

                        <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap">50 - 70</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <div className="bg-[#55BE8B] h-[16px] w-[16px] rounded-sm" />

                        <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap">71 - 100</p>
                    </div>
                </div>
            </div>
            <div className="xl:w-[65%]">
                {automated_scans?.length > 0 && (
                    <div className="border border-[#F1F5F9] rounded py-2">
                        <div className="flex justify-between items-center px-3">
                            <h4 className="text-[#2B3674] text-base font-bold">Automated Scans</h4>
                            <div className="flex items-center gap-1">
                                <FiberManualRecordIcon
                                    sx={{
                                        color:
                                            all_automated_scan_summary?.percent_passed < 50
                                                ? '#FF5449'
                                                : all_automated_scan_summary?.percent_passed >= 50 &&
                                                  all_automated_scan_summary?.percent_passed < 70
                                                ? '#F2C021'
                                                : '#55BE8B',
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                                <p className="text-gray-600 text-xs font-medium">
                                    {all_automated_scan_summary?.percent_passed?.toFixed(2)}%
                                </p>
                            </div>
                        </div>
                        {all_automated_scan_group?.map((option, index) => {
                            return (
                                automated_scans?.includes(option?.name) && (
                                    <div
                                        className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center"
                                        key={index}
                                    >
                                        <div className="flex items-center gap-1">
                                            {option.passed === option?.total && option?.total !== 0 ? (
                                                <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                                            ) : (
                                                <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                                            )}
                                            <p className="text-gray-600 text-xs font-normal capitalize">
                                                {option.name}
                                            </p>
                                        </div>
                                        <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                                            {option.passed} /{option.total}
                                        </p>
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}
                {standards?.length > 0 && (
                    <div className="border border-[#F1F5F9] rounded py-2 mt-[3rem]">
                        <div className="flex justify-between items-center px-3">
                            <h4 className="text-[#2B3674] text-base font-bold">Frameworks</h4>
                        </div>
                        {standards?.includes('pcidss') && <PCISummary />}
                        {standards?.includes('ndpr') && <NdprSummary />}
                        {standards?.includes('soc2') && <SOC2Summary />}
                        {standards?.includes('iso27001') && <ISO27001Summary />}
                        {standards?.includes('pcislc') && <SSLCSummary />}
                        {standards?.includes('iso22301') && <ISO22301Summary />}
                        {standards?.includes('pcidss4.0') && <PCI4Summary />}
                        {standards?.includes('iso27001-2022') && <ISO270012022Summary />}
                        {standards?.includes('iso9001') && <ISO9001Summary />}
                        {standards?.includes('kdpa') && <KDPAReport />}
                        {standards?.includes('gdpa') && <GDPAReport />}
                        {standards?.includes('iso27017+iso27032') && <ISO27017ISO27032Report />}
                        {standards?.includes('blockchain') && <BLOCKCHAINSummary />}
                        {standards?.includes('cii') && <CIISummary />}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportSummary1;
