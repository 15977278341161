import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, Grow, Stack, DialogActions } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});
const AppCenteredModal = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        children,
        title,
        subtitle,
        width,
        minWidth,
        actions,
        height,
        headerAction,
        padding,
        maxHeight,
        maxWidth,
        titleStyle = {},
        overflow,
        noBorder,
        sx = {},
        dialogActionsStyle = {},
    } = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: minWidth ? minWidth : 370,
                    borderRadius: noBorder ? '' : 2,
                    position: 'relative',
                    top: 0,
                    left: { md: '16%', lg: '0%' },
                    width,
                    maxWidth: maxWidth ? maxWidth : 'unset',
                    height: height ? height : 'unset',
                    maxHeight: maxHeight ? maxHeight : 550,
                    m: 0,
                    zIndex: 1002,
                    padding: padding ? padding : 'unset',
                    ...sx,
                },
            }}
            scroll="paper"
        >
            {title && (
                <DialogTitle
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.gray[900],
                        marginBottom: 0,
                        paddingBottom: '8px',
                        ...titleStyle,
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {title}
                        {headerAction}
                    </Stack>
                    {subtitle}
                </DialogTitle>
            )}
            <DialogContent
                sx={{
                    padding: padding ? padding : '20px 28px',
                    pb: 0.2,
                    overflow: overflow ? overflow : 'auto',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary[900] + '30',
                        borderRadius: '10px',
                    },
                }}
            >
                {children}
            </DialogContent>
            {actions && (
                <DialogActions sx={{ display: 'block', px: 3, ...dialogActionsStyle }}>{actions}</DialogActions>
            )}
        </Dialog>
    );
};

export default AppCenteredModal;
