import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import AuthNotificationBar from './AuthNotificationBar';
import AuthMethods from './AuthMethods';
import AuthControlSwitch from './AuthControlSwitch';
import AuthConnectionView from './AuthConnectionView';

//react-redux
import { connect } from 'react-redux';
import { GetUserDetails, UpdateUserDetails } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import AuthChangeConfirmModal from './Components/AuthChangeConfirmModal';
import ConfirmPasswordModal from './Components/ConfirmPasswordModal';

//translation
import { useTranslation } from 'react-i18next';

const TwoFactorAuth = (props) => {
    const [authType, setAuthType] = useState('');
    const [toggled, setToggled] = useState();
    const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [confirmPasswordModalOpen, setConfirmPasswordModalOpen] = useState(false);

    const { GetUserDetails, UpdateUserDetails, mfa, mfa_type } = props;

    //translation hook
    const { t } = useTranslation('accounts');

    const handleConnect = (type) => {
        if (mfa && mfa_type) {
            openConfirmChangeModal(type);
        } else {
            openAuthConnectionView(type);
        }
    };
    const handleDisconnect = (type) => {
        let typeOpposite = type === 'email' ? 'authenticator' : 'email';
        openConfirmChangeModal(typeOpposite);
    };
    const handleToggleSwitch = () => {
        openConfirmPasswordModal();
    };

    const handleTwoFASwitch = async () => {
        const res = await UpdateUserDetails({ mfa: !toggled });
        if (res?.success) {
            setToggled(!toggled);
            toast.success(`Two Factor Authentication turned ${!toggled === true ? 'on' : 'off'}`);
        } else {
            toast.error(res?.message);
        }
    };
    const goBack = () => {
        setAuthType('');
        setModalType('');
    };
    const openConfirmChangeModal = (type) => {
        setConfirmChangeModalOpen(true);
        setModalType(type);
    };
    const closeConfirmChangeModal = () => {
        setConfirmChangeModalOpen(false);
    };
    const openAuthConnectionView = (type) => {
        setAuthType(type);
    };
    const openConfirmPasswordModal = () => {
        setConfirmPasswordModalOpen(true);
    };
    const closeConfirmPasswordModal = () => {
        setConfirmPasswordModalOpen(false);
    };

    //async actions
    useEffect(() => {
        const getUserDetails = async () => {
            const res = await GetUserDetails();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);

    useEffect(() => {
        if (mfa) {
            setToggled(mfa);
        }
    }, [mfa]);
    return (
        <>
            <Box sx={{ p: 3, background: 'white', my: 3, width: '80%', mx: 'auto' }}>
                <Typography sx={{ color: '#202d66', fontSize: 20, mb: 3, fontWeight: 700 }}>
                    Two-factor Authentication
                </Typography>
                {!authType && (
                    <Box sx={{}}>
                        {!mfa && mfa_type ? <AuthNotificationBar /> : null}
                        <AuthControlSwitch
                            checked={toggled}
                            onChange={handleToggleSwitch}
                            mfa={mfa}
                            mfa_type={mfa_type}
                        />
                        <AuthMethods onClick={handleConnect} handleDisconnect={handleDisconnect} mfa_type={mfa_type} />
                    </Box>
                )}
            </Box>
            <Box>{authType && <AuthConnectionView authType={authType} goBack={goBack} mfa_type={mfa_type} />}</Box>
            <AuthChangeConfirmModal
                open={confirmChangeModalOpen}
                handleClose={closeConfirmChangeModal}
                title={`${t('twoFactorAuth.authChangeConfirmModal.title')} ${
                    modalType === 'authenticator'
                        ? t('twoFactorAuth.emailAuthenticator')
                        : t('twoFactorAuth.goggleAuthenticator')
                } to ${modalType === 'authenticator' ? 'Google Authenticator(TOTP)' : 'Email Authentication'}`}
                subtitle={`${t('twoFactorAuth.authChangeConfirmModal.subtitlePre')} ${
                    modalType !== 'authenticator' ? 'Google Authenticator(TOTP)' : 'Email Authenticaton'
                } ${t('twoFactorAuth.authChangeConfirmModal.subtitlePost')}`}
                openConfirmPasswordModal={openConfirmPasswordModal}
            />
            <ConfirmPasswordModal
                open={confirmPasswordModalOpen}
                handleClose={closeConfirmPasswordModal}
                type={modalType}
                openAuthConnectionView={openAuthConnectionView}
                handleTwoFASwitch={handleTwoFASwitch}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        mfa: state?.generalReducers?.user_info?.mfa,
        mfa_type: state?.generalReducers?.user_info?.mfa_type,
    };
};
export default connect(mapStateToProps, { GetUserDetails, UpdateUserDetails })(TwoFactorAuth);
