import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useMemo, useState } from 'react';

//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllTasksByStandard } from 'store/actions/taskActions';

//core components
import EastIcon from '@mui/icons-material/East';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SouthIcon from '@mui/icons-material/South';
import { LoadingButton } from '@mui/lab';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppViewModal from 'components/new_components/AppViewModal';
import LoadingState from 'components/new_components/LoadingState';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GetDocumentsByControl, GetEvidencesByControl, GetEvidencesBySubcontrol } from 'store/actions/adminActions';
import { DeleteEvidenceExemption, GetEvidenceExeption } from 'store/actions/complianceActions';
import { GetDocumentsQualityManagement, GetEvidencesByQualityManagement } from 'store/actions/iso9001';
import { GetDocumentsByControlObjective, GetEvidencesByControlObjective } from 'store/actions/pci_slcActions';
import { UpdateTask } from 'store/actions/taskActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { getResourceDetailsByStandard } from '../utils';
import EvidenceHistoryModal from './EvidenceHistoryModal';
import Evidences from './Evidences';
import ExemptEvidenceModal from './ExemptEvidenceModal';
import PolicyDocument from './PolicyDocumentItem';
import PolicyHistoryModal from './PolicyHistoryModal';
import UploadDocumentModal from './UploadDocumentModal';
import UploadEvidenceModal from './UploadEvidenceModal';

const DataRoomModal = (props) => {
    //props
    const {
        open,
        handleClose,
        all_requirements,
        get_all_evidences_metrics,
        all_metrics,
        GetEvidencesByControl,
        standard,
        all_evidences_metrics,
        evidence_type,
        all_merchant_documents,
        all_merchant_evidences,
        all_exempted_evidences,
        GetEvidenceExeption,
    } = props;
    // state
    const [type, setType] = useState('policies');
    const [segment, setSegment] = useState('');
    const [segmentNumber, setSegmentNumber] = useState();
    const [active, setActive] = useState();
    const [doc_id, setDoc_id] = useState();
    const [id, setid] = useState('');
    const [control, setControl] = useState();
    const [loading, setLoading] = useState(false);
    const [policyHistoryModal, setPolicyHistoryModal] = useState(false);
    const [evidenceHistoryModal, setEvidenceHistoryModal] = useState(false);
    const [uploadDocumentModal, setUploadDocuementModal] = useState(false);
    const [uploadEvidenceModal, setUploadEvidenceModal] = useState(false);
    const [exemptEvidenceModal, setExemptEvidenceModal] = useState(false);
    const [evidence, setEvidence] = useState(null);
    const [evidence_, setEvidence_] = useState(null);
    const [openRevertModal, setOpenRevertModal] = useState(false);
    const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('compliance');

    const { activeMerchant } = useContext(SelectedMerchantContext);

    const documents_by_control_objective = useSelector((state) => state?.pcislcReducers?.documents_by_control);
    const documents_by_management = useSelector((state) => state?.ISO9001Reducers?.documents_by_control);
    const documents_by_control_ = useSelector((state) => state?.adminReducers?.all_admin_docs_by_common_control);
    const evidences_by_control_ = useSelector((state) => state?.adminReducers?.all_admin_evidence_by_common_control);
    const evidences_by_control_objective = useSelector((state) => state?.pcislcReducers?.evidence_by_control);
    const evidences_by_management = useSelector((state) => state?.ISO9001Reducers?.evidence_by_control);
    const documents_by_control =
        standard === 'sslc'
            ? documents_by_control_objective
            : standard === 'iso9001'
            ? documents_by_management
            : documents_by_control_;
    const evidences_by_control =
        standard === 'sslc'
            ? evidences_by_control_objective
            : standard === 'iso9001'
            ? evidences_by_management
            : evidences_by_control_;

    const onClose = () => {
        setActive();
        handleClose();
        setType('policies');
    };

    // mui
    const theme = useTheme();

    const openHistoryModal = () => {
        setPolicyHistoryModal(true);
    };

    const closeHistoryModal = () => {
        setPolicyHistoryModal(false);
    };

    const openExemptEvidenceModal = (control) => {
        setEvidence_(control);
        setExemptEvidenceModal(true);
    };

    const closeExemptEvidenceModal = () => {
        setActive('');
        setEvidence_(null);
        getAllExemptedEvidences();
        setExemptEvidenceModal(false);
    };

    const openEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(true);
    };

    const closeEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(false);
    };

    const openUploadModal = () => {
        setUploadDocuementModal(true);
    };

    const closeUploadModal = () => {
        setUploadDocuementModal(false);
    };

    const openEvidenceModal = (item, number) => {
        setSegment(item);
        setSegmentNumber(number);
        setUploadEvidenceModal(true);
    };

    const closeEvidenceModal = () => {
        setUploadEvidenceModal(false);
    };

    const handleOpenRevert = (id) => {
        setid(id);
        setOpenRevertModal(true);
    };

    const handleCloseRevert = () => {
        setid('');
        setActive('');
        get_all_evidences_metrics();
        setOpenRevertModal(false);
    };

    // async functions soc2
    const getControlDocuments = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const isCard4 = evidence_type === 'card4';
        const res = await dispatch(
            GetDocumentsByControl(subcontrolId, isCard4 ? 'card4_id' : '', isCard4 ? 'card4' : '')
        );
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const getControlEvidences = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const isCard4 = evidence_type === 'card4';
        const res = await GetEvidencesByControl(subcontrolId, isCard4 ? 'card4_id' : '', isCard4 ? 'card4' : '');
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const getSoc2FilteredControls = (items) => {
        const filteredControls = all_requirements?.filter((control) => {
            const control_id = control?.id;
            const filtered_numbers = control?.sub_controls?.map((item) => item?.title);

            const controlHasExemption = items?.some((exemption) => {
                const exemptionControlID = exemption?.evidence_detail?.common_control;
                return (
                    exemptionControlID === control_id &&
                    filtered_numbers?.includes(exemption.evidence_detail?.sub_control)
                );
            });
            return controlHasExemption;
        });
        const controlsWithFilteredSubcontrols = filteredControls?.map((control) => {
            const filteredSubcontrols = control.sub_controls.filter((sub_control) => {
                const subcontrolTitle = sub_control?.title;
                const subcontrolHasExemption = items?.some((exemption) => {
                    const exemptionSubcontrol = exemption?.evidence_detail?.sub_control;
                    return exemptionSubcontrol === subcontrolTitle;
                });
                return subcontrolHasExemption;
            });

            control.sub_lifecycle = filteredSubcontrols;
            return control;
        });
        const formattedControls = controlsWithFilteredSubcontrols?.map((control) => {
            return {
                id: control?.id,
                title: control?.title,
                description: control?.service_category,
                subgroups: control?.sub_controls?.map((subcontrol) => ({
                    label: `${t('sub')} ${subcontrol?.title}`,
                    id: subcontrol?.title,
                })),
            };
        });
        return formattedControls;
    };

    // async functions pcislc
    const getControlObjectiveDocuments = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await dispatch(GetDocumentsByControlObjective(subcontrolId));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const getControlObjectiveEvidences = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await dispatch(GetEvidencesByControlObjective(subcontrolId));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    // async functions pcislc
    const getQualityManagmentDocuments = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await dispatch(GetDocumentsQualityManagement(subcontrolId));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const getQualityManagmentEvidences = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await dispatch(GetEvidencesByQualityManagement(subcontrolId));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === doc_id);
    }, [doc_id, all_merchant_documents]);

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === doc_id);
    }, [doc_id, all_merchant_evidences]);

    const handleSubControlDocument = (id) => {
        if (standard === 'sslc') {
            getControlObjectiveDocuments(id);
        } else if (standard === 'iso9001') {
            getQualityManagmentDocuments(id);
        } else {
            getControlDocuments(id);
        }
    };

    // get all excempted document for this standard
    const getAllExemptedEvidences = async () => {
        setLoading(true);
        const res = await GetEvidenceExeption(standard, activeMerchant);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    const controlsWithExceptions = useMemo(() => {
        if (standard === 'soc2') {
            return getSoc2FilteredControls(all_exempted_evidences || []);
        }
    }, [all_exempted_evidences]);

    useEffect(() => {
        getAllExemptedEvidences();
    }, [activeMerchant]);

    // console.log({ all_exempted_evidences, all_requirements });

    return (
        <AppViewModal sx={{ overflowX: 'hidden' }} open={open} handleClose={onClose} width={520}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={onClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Data Room
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mx: standard === 'soc2' ? '3rem' : '7rem',
                        mt: 4,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'policies' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'right',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('policies');
                            }}
                        >
                            Policies
                        </Typography>
                        {type === 'policies' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'evidences' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'left',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('evidences');
                            }}
                        >
                            Evidences
                        </Typography>
                        {type === 'evidences' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                    {standard === 'soc2' ? (
                        <Box>
                            <Typography
                                sx={{
                                    color: type === 'exempted-evidences' ? '#202D66' : '#94A3B8',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setActive();
                                    setType('exempted-evidences');
                                }}
                            >
                                Exempted Evidences
                            </Typography>
                            {type === 'exempted-evidences' && (
                                <Box
                                    sx={{
                                        backgroundColor: '#202D66',
                                        width: '100%',
                                        pt: 0.5,
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                    }}
                                ></Box>
                            )}
                        </Box>
                    ) : null}
                </Box>
                <Divider sx={{ mx: '-3.5rem' }} />
                <Box sx={{ mt: 4 }}>
                    {type === 'policies' ? (
                        <Box>
                            {all_requirements?.map((requirement) => {
                                const { total_doc, user_doc } =
                                    all_metrics?.[requirement?.title || requirement?.lifecycle_title] || {};
                                const metric = { totalValue: total_doc, totalAttendedTo: user_doc };
                                return (
                                    <Box key={requirement?.id}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleSubControlDocument(requirement?.id)}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === requirement?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{ color: '#475569', fontSize: '15px', fontWeight: 600, ml: 2 }}
                                                >
                                                    {requirement?.title ||
                                                        requirement?.lifecycle_title ||
                                                        requirement?.requirement_title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color:
                                                            metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                                ? ''
                                                                : metric?.totalAttendedTo > 0 &&
                                                                  metric?.totalAttendedTo < metric?.totalValue
                                                                ? '#D2A500'
                                                                : '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: metric?.totalAttendedTo === 0 ? '#77777A' : '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {metric?.totalAttendedTo > 0 &&
                                                    metric?.totalAttendedTo < metric?.totalValue
                                                        ? 'Incomplete Uploads'
                                                        : metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                        ? 'No uploads yet'
                                                        : 'Completed'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === requirement?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {loading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <>
                                                        {documents_by_control?.length > 0 ? (
                                                            <Box>
                                                                {documents_by_control?.map((control) => (
                                                                    <PolicyDocument
                                                                        key={control?.id}
                                                                        control={control}
                                                                        setDoc_id={setDoc_id}
                                                                        openHistoryModal={openHistoryModal}
                                                                        standard={standard}
                                                                        closeHistoryModal={closeHistoryModal}
                                                                        policyHistoryModal={policyHistoryModal}
                                                                        openUploadModal={openUploadModal}
                                                                        doc_id={doc_id}
                                                                        getControlDocuments={getControlDocuments}
                                                                        subcontrolId={requirement?.id}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: '#475569',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <Typography>No Policy Document Found</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : null}

                    {type === 'evidences' ? (
                        <Box>
                            {all_requirements?.map((requirement) => {
                                const { total_evidences, user_evidences } =
                                    all_evidences_metrics?.[requirement?.title || requirement?.lifecycle_title] || {};
                                const metric = { totalValue: total_evidences, totalAttendedTo: user_evidences };
                                return (
                                    <Box key={requirement?.id}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (standard === 'sslc') {
                                                    getControlObjectiveEvidences(requirement?.id);
                                                } else if (standard === 'iso9001') {
                                                    getQualityManagmentEvidences(requirement?.id);
                                                } else {
                                                    getControlEvidences(requirement?.id);
                                                }
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === requirement?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{ color: '#475569', fontSize: '15px', fontWeight: 600, ml: 2 }}
                                                >
                                                    {requirement?.lifecycle_title ||
                                                        requirement?.title ||
                                                        requirement?.requirement_title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color:
                                                            metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                                ? ''
                                                                : metric?.totalAttendedTo > 0 &&
                                                                  metric?.totalAttendedTo < metric?.totalValue
                                                                ? '#D2A500'
                                                                : '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: metric?.totalAttendedTo === 0 ? '#77777A' : '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {metric?.totalAttendedTo > 0 &&
                                                    metric?.totalAttendedTo < metric?.totalValue
                                                        ? 'Incomplete Uploads'
                                                        : metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                        ? 'No uploads yet'
                                                        : 'Completed'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === requirement?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {loading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <>
                                                        {evidences_by_control?.length > 0 ? (
                                                            <Box>
                                                                {evidences_by_control.map((control) => (
                                                                    <Evidences
                                                                        key={control?.id}
                                                                        control={control}
                                                                        setDoc_id={setDoc_id}
                                                                        openEvidenceHistoryModal={
                                                                            openEvidenceHistoryModal
                                                                        }
                                                                        closeEvidenceHistoryModal={
                                                                            closeEvidenceHistoryModal
                                                                        }
                                                                        openExemptEvidenceModal={
                                                                            openExemptEvidenceModal
                                                                        }
                                                                        evidenceHistoryModal={evidenceHistoryModal}
                                                                        standard={standard}
                                                                        doc_id={doc_id}
                                                                        req_id={requirement}
                                                                        setControl={setControl}
                                                                        evidence_type={evidence_type}
                                                                        selectedEvidence={selectedEvidence}
                                                                        setControlModal={setEvidence}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: '#475569',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <Typography>No Evidence Required</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : null}

                    {type === 'exempted-evidences' ? (
                        loading ? (
                            <LoadingState />
                        ) : controlsWithExceptions?.length ? (
                            controlsWithExceptions?.map((group) => {
                                const all_filtered_exceptions = all_exempted_evidences?.filter((item) => {
                                    if (standard === 'soc2') {
                                        return item?.evidence_detail?.common_control === group?.id;
                                    }
                                });

                                // console.log({ group, all_filtered_exceptions });

                                return (
                                    <Box key={group?.id}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === group?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (active === group?.id) {
                                                    setActive('');
                                                } else {
                                                    setActive(group?.id);
                                                }
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === group?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{
                                                        color: '#475569',
                                                        fontSize: '15px',
                                                        fontWeight: 600,
                                                        ml: 2,
                                                    }}
                                                >
                                                    {group?.title}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color: '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {all_filtered_exceptions?.length} exempted
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === group?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {all_filtered_exceptions?.map((item) => (
                                                    <Box key={item?.id}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                py: 1,
                                                                gap: 2,
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <img src={FileUnCheck} alt="fileunckeck" />
                                                                <Typography
                                                                    sx={{
                                                                        ml: 2,
                                                                        color: '#395BA9',
                                                                        fontSize: '13px',
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    {item?.evidence_detail?.soc2_title}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="text"
                                                                sx={{
                                                                    backgroundColor: '#F8FAFC',
                                                                    color: '#64748B',
                                                                    textTransform: 'inherit',
                                                                    p: 0.3,
                                                                    fontSize: '13px',
                                                                    fontWeight: 500,
                                                                    border: '1px solid #E2E8F0',
                                                                    '&:hover': {
                                                                        backgroundColor: '#F8FAFC',
                                                                        border: '1px solid #E2E8F0',
                                                                    },
                                                                }}
                                                                onClick={() => handleOpenRevert(item?.id)}
                                                            >
                                                                Revert
                                                            </Button>
                                                        </Box>
                                                        <Divider sx={{ color: '#F1F5F9', mx: '-2rem' }} />
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#475569',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    marginTop: '8rem',
                                }}
                            >
                                <Typography>You have no exempted evidences</Typography>
                            </Box>
                        )
                    ) : null}
                </Box>
            </Box>
            <UploadDocumentModal
                open={uploadDocumentModal}
                handleClose={closeUploadModal}
                doc_id={doc_id}
                standard={standard}
            />

            <UploadEvidenceModal
                open={uploadEvidenceModal}
                handleClose={closeEvidenceModal}
                doc_id={doc_id}
                standard={standard}
                control={control?.id}
                evidence_type={evidence_type}
                segment={segment}
                segmentNumber={segmentNumber}
            />

            <PolicyHistoryModal
                open={policyHistoryModal}
                handleClose={closeHistoryModal}
                selected={selectedDocument}
                document={getResourceDetailsByStandard(selectedDocument, standard)}
                doc_id={doc_id}
            />
            <EvidenceHistoryModal
                open={evidenceHistoryModal}
                handleClose={closeEvidenceHistoryModal}
                selected={selectedEvidence}
                doc_id={doc_id}
                evidence_type={evidence_type}
                evidence={evidence}
                openEvidenceModal={openEvidenceModal}
                control={control?.id}
                standard={standard}
            />
            <ExemptEvidenceModal
                open={exemptEvidenceModal}
                handleClose={closeExemptEvidenceModal}
                evidence={evidence_}
                standard={standard}
            />
            <RevertModal
                open={openRevertModal}
                handleClose={handleCloseRevert}
                id={id}
                onExempted={getAllExemptedEvidences}
                standard={standard}
            />
        </AppViewModal>
    );
};

function RevertModal(props) {
    const { open, handleClose, id, onExempted, standard } = props;
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    // functions
    const removeException = async () => {
        setLoading(true);
        const res = await dispatch(DeleteEvidenceExemption(id, standard));
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            onExempted();
            handleClose();
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        gap: 2,
                        py: 2,
                        pr: 3,
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            mr: 2,
                            backgroundColor: '#37A372',
                            textTransform: 'inherit',
                            color: '#FFFFFF',
                            px: 2,
                            '&:hover': {
                                backgroundColor: '#37A372',
                                color: '#FFFFFF',
                            },
                        }}
                        loading={loading}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        onClick={removeException}
                    >
                        Yes, revert
                    </LoadingButton>
                </Box>
            }
            sx={{ borderRadius: 1, overflowX: 'auto' }}
            width="500px"
        >
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    Revert Evidence?
                </Typography>
                <Typography sx={{ color: '#475569', fontSize: '13px', fontWeight: 400, textAlign: 'center', my: 2 }}>
                    Reverting this evidence means it will be amongst your compliance implementation process. Are you
                    sure?
                </Typography>
            </Box>
        </AppCenteredModal>
    );
}

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
        all_exempted_evidences: state?.complianceReducers?.all_exempted_evidences,
    };
};
export default connect(mapStateToProps, {
    GetAllTasksByStandard,
    UpdateTask,
    GetEvidencesByControl,
    GetEvidencesBySubcontrol,
    GetEvidenceExeption,
})(DataRoomModal);
