import React from 'react';
//core component
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircle } from '@mui/icons-material';
import ConnectButton from './ConnectButton';

const AuthMethodItem = (props) => {
    const theme = useTheme();
    const { icon, type, header, description, handleConnect, handleDisconnect, mfa_type } = props;
    return (
        <Box
            sx={{
                background: '#ffffff',
                px: 1,
                py: 2,
                borderBottom: '1px solid #F1F5F9',
            }}
        >
            <Stack direction={{ lg: 'row', sm: 'row', md: 'row' }} justifyContent="space-between" alignItems="start">
                <div className="flex items-start  justify-start gap-20">
                    <img src={icon} alt={type} style={{ width: '4rem', height: '4rem' }} />
                    <Box sx={{ width: '54%', ml: '-58px' }}>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                mb: 1,
                                color: '#202D66',
                            }}
                        >
                            {header}
                            {mfa_type === type && <CheckCircle color={theme.palette.primary.main} sx={{ ml: 1 }} />}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: 15,
                                color: '#5E5E62',
                            }}
                        >
                            {description}
                        </Typography>
                    </Box>
                </div>

                <ConnectButton
                    handleConnect={() => handleConnect(type)}
                    connected={mfa_type === type}
                    mfa_type={mfa_type}
                    handleDisconnect={handleDisconnect}
                />
            </Stack>
        </Box>
    );
};

export default AuthMethodItem;
