/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import LightBulbIcon from 'assets/img/lightbulb.png';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    inherentLikelihoodOrImpact3x3,
    inherentLikelihoodOrImpact3x3_,
    inherentLikelihoodOrImpact5x5,
    inherentLikelihoodOrImpact5x5_,
} from '../../utils/constants';
import NewVulnerability from './identificationComponents/NewVulnerability';
import Threats from './identificationComponents/Threats';

export default function RiskIdentification({
    riskFirstPageStates,
    setRiskFirstPageStates,
    loading,
    error,
    cia_impact_,
    matrix,
    setMatrix,
    assets,
    setAssets,
    vulnerabilities,
    setVulnerablities,
}) {
    const [localRiskIdentificationStates, setLocalRiskIdentificationStates] = useState({
        name: riskFirstPageStates?.name,
        additional_notes: riskFirstPageStates?.additional_notes,
        threat: riskFirstPageStates?.threat,
        existing_controls: riskFirstPageStates?.existing_controls,
        frameworks: riskFirstPageStates?.frameworks?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        }),
    });

    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);

    const by5Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact5x5
            : inherentLikelihoodOrImpact5x5_ || inherentLikelihoodOrImpact5x5;
    const by3Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact3x3
            : inherentLikelihoodOrImpact3x3_ || inherentLikelihoodOrImpact3x3;
    const [inherentLikelihoodOrImpact, setInherentLikelihoodOrImpact] = useState(
        matrix === '5x5' ? [...by5Matrix] : [...by3Matrix]
    );
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [threatyAnchorEl, setThreatAnchorEl] = useState(null);
    const [likelihoodAnchorEl, setLikelihoodAnchorEl] = useState(null);
    const [impactAnchorEl, setImpactAnchorEl] = useState(null);
    const [assetAnchorEl, setAssetAnchorEl] = useState(null);
    const [vulnerabilitiesAnchorEl, setVulnerabilitiesAnchorEl] = useState(null);
    const [frameworkAnchorEl, setFrameworkAnchorEl] = useState('');
    const [search, setSearch] = useState('');
    const { risk_assessment_categories, all_merchant_assets, risk_assessment_vulnerabilities } = useSelector(
        (state) => state.riskAssessmentReducers
    );
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    useEffect(() => {
        if (matrix === '5x5') {
            setInherentLikelihoodOrImpact([...by5Matrix]);
        } else {
            setInherentLikelihoodOrImpact([...by3Matrix]);
        }
    }, [matrix]);

    const filtered_assets = assets?.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
    });
    // const filtered_frameworks = riskFirstPageStates?.frameworks?.filter(function (item, index, inputArray) {
    //     return inputArray.indexOf(item) == index;
    // });

    const filtered_frameworks_ = merchant_info?.paid_standards
        ?.split(',')
        ?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        })
        ?.filter((framework) => framework?.toLowerCase().includes(search.toLowerCase()));
    const filtered_merchant_assets_with_category = all_merchant_assets
        ?.filter((asset) => !filtered_assets?.includes(asset))
        ?.filter((asset) => asset?.risk_category === riskFirstPageStates?.category);
    const filtered_merchant_assets_without_category = all_merchant_assets
        ?.filter((asset) => !filtered_assets?.includes(asset))
        ?.filter((asset) => !asset?.risk_category);
    const filtered_vulnerabilities = vulnerabilities?.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
    });
    const filtered_risk_assessment_vulnerabilities = risk_assessment_vulnerabilities
        ?.filter((asset) => !filtered_vulnerabilities?.includes(asset))
        ?.filter((vulnerability) => vulnerability?.threat === riskFirstPageStates?.threat);
    return (
        <>
            <div className="flex flex-col gap-4 mb-5">
                <div className="bg-[#F8FAFC] border border-[#F0F0FA] flex items-center gap-2 py-3 px-[1rem] rounded">
                    <img src={LightBulbIcon} alt="note" />
                    <p className="text-[#64748B] text-sm font-medium">
                        You can save risk assessments on assets without having to fill all the form in this module
                    </p>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Risk matrix <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip
                            title="Use this tool to assess and prioritize risks based on their likelihood and impact."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div className="flex gap-4">
                        <button
                            className={`flex flex-col justify-center items-center gap-1 !p-2 bg-white transition-all hover:brightness-95 !rounded h-[77px] w-[75px] ${
                                matrix === '5x5'
                                    ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                    : '!border !border-[#94A3B8] text-[#94A3B8]'
                            }`}
                            onClick={() => {
                                setRiskFirstPageStates((prev) => ({
                                    ...prev,
                                    inherentLikelihood: '',
                                    iinherent_impact: '',
                                }));
                                setMatrix('5x5');
                            }}
                        >
                            <p className="font-bold text-2xl">5x5</p>
                            <p className="font-medium text-sm">Matrix</p>
                        </button>

                        <button
                            className={`flex flex-col justify-center items-center gap-1 !p-2 bg-white transition-all hover:brightness-95 !rounded h-[77px] w-[75px] ${
                                matrix === '3x3'
                                    ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                    : '!border !border-[#94A3B8] text-[#94A3B8]'
                            }`}
                            onClick={() => {
                                setRiskFirstPageStates((prev) => ({
                                    ...prev,
                                    inherentLikelihood: '',
                                    inherent_impact: '',
                                }));
                                setMatrix('3x3');
                            }}
                        >
                            <p className="font-bold text-2xl">3x3</p>

                            <p className="font-medium text-sm">Matrix</p>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-2 relative">
                    <p className="font-medium text-sm text-[#64748B]">
                        Risk subject/name <span className="text-[#DE3730] font-semibold">*</span>
                    </p>

                    <input
                        type="text"
                        placeholder="| E.g Data Loss in Cloud Storage..."
                        value={localRiskIdentificationStates.name}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                name: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskFirstPageStates((prev) => ({
                                ...prev,
                                name: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                    {error && riskFirstPageStates?.name === '' && (
                        <span className="text-xs text-[#FF0000] font-normal mt-2">Risk subject is required</span>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Category <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip title="Classify the risk based on its nature or source." placement="top">
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={categoryAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCategoryAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    riskFirstPageStates?.category
                                        ? 'capitalize text-[hsl(215,20%,40%)]'
                                        : 'text-[#94A3B8]'
                                }`}
                            >
                                {riskFirstPageStates?.category
                                    ? risk_assessment_categories?.find(
                                          (category) => category?.id === riskFirstPageStates?.category
                                      )?.name
                                    : 'Select category'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={categoryAnchorEl}
                            open={Boolean(categoryAnchorEl)}
                            onClose={() => setCategoryAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                            disabled
                        >
                            <div className="!p-2 flex flex-col min-w-[224px]">
                                {risk_assessment_categories?.map((category) => (
                                    <MenuItem
                                        key={category?.id}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setRiskFirstPageStates((prev) => ({
                                                ...prev,
                                                category: category?.id,
                                            }));
                                            if (riskFirstPageStates?.category !== category?.id) {
                                                setRiskFirstPageStates((prev) => ({
                                                    ...prev,
                                                    threat: '',
                                                }));
                                                setVulnerablities([]);
                                            }
                                            setCategoryAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {category?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && riskFirstPageStates?.category === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Category is required</span>
                        )}
                    </div>
                </div>

                <div className="flex-1 flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B]">
                        Asset(s)<span className="text-[#DE3730] font-semibold">*</span>
                    </p>
                    <div className="relative w-full">
                        <div
                            className="flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer "
                            aria-controls={assetAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={assetAnchorEl ? 'true' : undefined}
                            onClick={(event) => setAssetAnchorEl(event.currentTarget)}
                        >
                            {filtered_assets?.length ? (
                                <>
                                    {filtered_assets?.map((asset, index) => (
                                        <button
                                            key={index}
                                            className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setAssets(assets?.filter((item) => item?.id !== asset?.id));
                                            }}
                                        >
                                            <p className="font-normal capitalize text-[13px] text-[#64748B]">
                                                {asset?.name ||
                                                    asset?.vendor_details?.email ||
                                                    `${asset?.person_details?.first_name} ${asset?.person_details?.last_name}` ||
                                                    `${asset?.person_details?.email}`}
                                            </p>

                                            <img
                                                src="/img/risk-assessment/x-close-small.svg"
                                                alt="Delete Icon"
                                                className="object-contain"
                                            />
                                        </button>
                                    ))}
                                </>
                            ) : (
                                <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                    {assets?.length === 0 && 'Choose asset'}
                                </span>
                            )}
                        </div>
                        <Menu
                            anchorEl={assetAnchorEl}
                            open={Boolean(assetAnchorEl)}
                            onClose={() => setAssetAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="flex justify-between items-start p-2 min-h-[200px] max-h-[300px] overflow-auto">
                                <div className="!p-2 flex flex-col min-w-[224px]">
                                    <p className="text-[#94A3B8] text-[13px] font-normal mb-2">Standalone assets</p>
                                    {(filtered_merchant_assets_with_category?.length
                                        ? filtered_merchant_assets_with_category
                                        : filtered_merchant_assets_without_category
                                    )?.filter((asset) => asset?.group === null)?.length ? (
                                        <>
                                            {(filtered_merchant_assets_with_category?.length
                                                ? filtered_merchant_assets_with_category
                                                : filtered_merchant_assets_without_category
                                            )
                                                ?.filter((asset) => asset?.group === null)
                                                ?.map((asset) => (
                                                    <MenuItem
                                                        key={asset?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setAssets([...assets, asset]?.filter(Boolean));
                                                        }}
                                                        sx={{
                                                            color: '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '13px',
                                                            textTransform: 'capitalize',
                                                            padding: '6px 8px',
                                                        }}
                                                    >
                                                        {asset?.name ||
                                                            asset?.vendor_details?.name ||
                                                            asset?.vendor_details?.email ||
                                                            `${asset?.person_details?.first_name} ${asset?.person_details?.first_name}` ||
                                                            `${asset?.person_details?.email}`}
                                                    </MenuItem>
                                                ))}
                                        </>
                                    ) : (
                                        <p className="text-[#94A3B8] text-sm mt-5">Asset not found</p>
                                    )}
                                </div>
                                <div className="!p-2 flex flex-col min-w-[224px]">
                                    <p className="text-[#94A3B8] text-[13px] font-normal mb-2">Grouped assets</p>
                                    {(filtered_merchant_assets_with_category?.length
                                        ? filtered_merchant_assets_with_category
                                        : filtered_merchant_assets_without_category
                                    )?.filter((asset) => asset?.group)?.length ? (
                                        <>
                                            {(filtered_merchant_assets_with_category?.length
                                                ? filtered_merchant_assets_with_category
                                                : filtered_merchant_assets_without_category
                                            )
                                                ?.filter((asset) => asset?.group)
                                                ?.map((asset) => (
                                                    <MenuItem
                                                        key={asset?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setAssets([...assets, asset]?.filter(Boolean));
                                                        }}
                                                        sx={{
                                                            color: riskFirstPageStates?.assets?.includes(asset)
                                                                ? '#FFFFFF'
                                                                : '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '13px',
                                                            textTransform: 'capitalize',
                                                            padding: '6px 8px',
                                                            backgroundColor: riskFirstPageStates?.assets?.includes(
                                                                asset
                                                            )
                                                                ? '#202D66'
                                                                : '',
                                                            '&:hover': {
                                                                backgroundColor: riskFirstPageStates?.assets?.includes(
                                                                    asset
                                                                )
                                                                    ? '#202D66'
                                                                    : '',
                                                                color: riskFirstPageStates?.assets?.includes(asset)
                                                                    ? '#FFFFFF'
                                                                    : '#64748B',
                                                            },
                                                        }}
                                                    >
                                                        {asset?.name ||
                                                            asset?.vendor_details?.name ||
                                                            asset?.vendor_details?.email ||
                                                            `${asset?.person_details?.first_name} ${asset?.person_details?.first_name}`}
                                                    </MenuItem>
                                                ))}
                                        </>
                                    ) : (
                                        <p className="text-[#94A3B8] text-sm mt-5">Asset not found</p>
                                    )}
                                </div>
                            </div>
                        </Menu>
                        {error && filtered_merchant_assets_with_category?.length > 0 && assets?.length === 0 && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Please select an asset</span>
                        )}
                    </div>
                </div>

                <div className="flex items-start gap-4">
                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">
                                Threat <span className="text-[#DE3730] font-semibold">*</span>
                            </p>

                            <CustomTooltip
                                title="Identify potential events or actions that could harm the asset."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>

                        <Threats
                            localRiskIdentificationStates={localRiskIdentificationStates}
                            setLocalRiskIdentificationStates={setLocalRiskIdentificationStates}
                            setRiskFirstPageStates={setRiskFirstPageStates}
                            loading={loading}
                            threatAnchorEl={threatyAnchorEl}
                            setThreatAnchorEl={setThreatAnchorEl}
                            riskFirstPageStates={riskFirstPageStates}
                            setVulnerablities={setVulnerablities}
                        />
                        {error && riskFirstPageStates?.threat === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Threat is required</span>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">
                                Vulnerabilities <span className="text-[#DE3730] font-semibold">*</span>
                            </p>

                            <CustomTooltip
                                title="Highlight weaknesses that could be exploited by threats."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        <div className="relative w-full">
                            <div
                                className="flex flex-wrap gap-2 !border max-h-24 overflow-y-auto !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer "
                                aria-controls={vulnerabilitiesAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={vulnerabilitiesAnchorEl ? 'true' : undefined}
                                onClick={(event) => setVulnerabilitiesAnchorEl(event.currentTarget)}
                            >
                                {filtered_vulnerabilities?.length ? (
                                    <>
                                        {filtered_vulnerabilities?.map((vulnerability, index) => (
                                            <button
                                                key={index}
                                                className="flex items-start gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded py-2 !px-2 hover:brightness-90 transition-all mb-2"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setVulnerablities(
                                                        vulnerabilities?.filter(
                                                            (item) => item?.id !== vulnerability?.id
                                                        )
                                                    );
                                                }}
                                            >
                                                <p className="font-normal text-left capitalize text-[13px] text-[#64748B]">
                                                    {vulnerability?.name}
                                                </p>

                                                <img
                                                    src="/img/risk-assessment/x-close-small.svg"
                                                    alt="Delete Icon"
                                                    className="object-contain"
                                                />
                                            </button>
                                        ))}
                                    </>
                                ) : (
                                    <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                        {assets?.length === 0 && 'Choose Vulnerability'}
                                    </span>
                                )}
                            </div>
                            <Menu
                                anchorEl={vulnerabilitiesAnchorEl}
                                open={Boolean(vulnerabilitiesAnchorEl)}
                                onClose={() => setVulnerabilitiesAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                    pb: 3,
                                }}
                            >
                                <div className="flex justify-between items-start p-2 min-h-[100px] max-h-[300px] overflow-auto">
                                    <div className="!p-2 flex flex-col w-fit">
                                        {filtered_risk_assessment_vulnerabilities?.length ? (
                                            <>
                                                {filtered_risk_assessment_vulnerabilities?.map((vulnerability) => (
                                                    <MenuItem
                                                        key={vulnerability?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setVulnerablities(
                                                                [...vulnerabilities, vulnerability]?.filter(Boolean)
                                                            );
                                                        }}
                                                        sx={{
                                                            color: vulnerabilities?.includes(vulnerability)
                                                                ? '#FFFFFF'
                                                                : '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '13px',
                                                            textTransform: 'capitalize',
                                                            padding: '6px 8px',
                                                            backgroundColor: vulnerabilities?.includes(vulnerability)
                                                                ? '#202D66'
                                                                : '',
                                                            '&:hover': {
                                                                backgroundColor: vulnerabilities?.includes(
                                                                    vulnerability
                                                                )
                                                                    ? '#202D66'
                                                                    : '',
                                                                color: vulnerabilities?.includes(vulnerability)
                                                                    ? '#FFFFFF'
                                                                    : '#64748B',
                                                            },
                                                        }}
                                                    >
                                                        {vulnerability?.name}
                                                    </MenuItem>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="text-[#94A3B8] text-sm mt-5 flex items-center justify-center ">
                                                Vulnerability not found
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {localRiskIdentificationStates?.threat && (
                                    <div className="flex justify-center items-center mb-3">
                                        <NewVulnerability
                                            riskFirstPageStates={riskFirstPageStates}
                                            setRiskFirstPageStates={setRiskFirstPageStates}
                                        />
                                    </div>
                                )}
                            </Menu>
                            {error && vulnerabilities?.length === 0 && (
                                <span className="text-xs text-[#FF0000] font-normal mt-2">Please select an asset</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Existing controls</p>

                        <CustomTooltip
                            title="List the measures already in place to mitigate or manage the risk."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <textarea
                        placeholder="Enter existing control"
                        value={localRiskIdentificationStates.existing_controls}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                existing_controls: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskFirstPageStates((prev) => ({
                                ...prev,
                                existing_controls: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Inherent likelihood <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip
                            title="Estimate the probability of risk occurrence without any controls in place."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={likelihoodAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={likelihoodAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setLikelihoodAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    riskFirstPageStates?.inherent_likelihood
                                        ? 'capitalize text-[hsl(215,20%,40%)]'
                                        : 'text-[#94A3B8]'
                                }`}
                            >
                                {riskFirstPageStates?.inherent_likelihood
                                    ? inherentLikelihoodOrImpact?.find(
                                          (item) => item?.value === riskFirstPageStates?.inherent_likelihood
                                      )?.name
                                    : 'Select current likelihood'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={likelihoodAnchorEl}
                            open={Boolean(likelihoodAnchorEl)}
                            onClose={() => setLikelihoodAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                            disabled
                        >
                            <div className="!p-2 flex flex-col min-w-[421px]">
                                {inherentLikelihoodOrImpact.map((level, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setRiskFirstPageStates((prev) => ({
                                                ...prev,
                                                inherent_likelihood: level?.value,
                                            }));
                                            setLikelihoodAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {level?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && riskFirstPageStates?.inherent_likelihood === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Likelihood is required</span>
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Inherent impact <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip
                            title="Evaluate the potential consequence of the risk occurring without controls."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    {cia_impact_ ? (
                        <button className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 h-9">
                            <p className="font-normal text-sm capitalize text-[hsl(215,20%,40%)]">
                                {
                                    inherentLikelihoodOrImpact?.find(
                                        (item) => item?.value === riskFirstPageStates?.inherent_impact
                                    )?.name
                                }
                            </p>
                        </button>
                    ) : (
                        <div>
                            <button
                                aria-controls={impactAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={impactAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setImpactAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        riskFirstPageStates?.inherent_impact
                                            ? 'capitalize text-[hsl(215,20%,40%)]'
                                            : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {riskFirstPageStates?.inherent_impact
                                        ? inherentLikelihoodOrImpact?.find(
                                              (item) => item?.value === riskFirstPageStates?.inherent_impact
                                          )?.name
                                        : 'Select current impact'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={impactAnchorEl}
                                open={Boolean(impactAnchorEl)}
                                onClose={() => setImpactAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                                disabled
                            >
                                <div className="!p-2 flex flex-col min-w-[421px]">
                                    {inherentLikelihoodOrImpact.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRiskFirstPageStates((prev) => ({
                                                    ...prev,
                                                    inherent_impact: level?.value,
                                                }));
                                                setImpactAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'capitalize',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {level?.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                            {error && riskFirstPageStates?.inherent_impact === '' && (
                                <span className="text-xs text-[#FF0000] font-normal mt-2">Impact is required</span>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex-1 flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B]">Linked Frameworks</p>
                    <div className="relative">
                        <div>
                            <div
                                className="w-full flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer"
                                aria-controls={frameworkAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={frameworkAnchorEl ? 'true' : undefined}
                                onClick={(event) => setFrameworkAnchorEl(event.currentTarget)}
                            >
                                {localRiskIdentificationStates?.frameworks?.length ? (
                                    <>
                                        {localRiskIdentificationStates?.frameworks?.map((framework, index) => (
                                            <button
                                                key={index}
                                                className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setRiskFirstPageStates((prev) => ({
                                                        ...prev,
                                                        frameworks: prev.frameworks.filter(
                                                            (item) => item !== framework
                                                        ),
                                                    }));
                                                }}
                                            >
                                                <p className="font-normal capitalize text-[13px] text-[#64748B]">
                                                    {framework}
                                                </p>

                                                <img
                                                    src="/img/risk-assessment/x-close-small.svg"
                                                    alt="Delete Icon"
                                                    className="object-contain"
                                                />
                                            </button>
                                        ))}
                                    </>
                                ) : (
                                    <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                        {localRiskIdentificationStates?.frameworks?.length === 0 && 'Choose framework'}
                                    </span>
                                )}
                            </div>{' '}
                            <Menu
                                anchorEl={frameworkAnchorEl}
                                open={Boolean(frameworkAnchorEl)}
                                onClose={() => {
                                    setFrameworkAnchorEl(null);
                                    setRiskFirstPageStates((prev) => ({
                                        ...prev,
                                        frameworks: localRiskIdentificationStates?.frameworks,
                                    }));
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                                PaperProps={{
                                    style: {
                                        width: 300,
                                    },
                                }}
                            >
                                <div className=" min-h-[200px] max-h-[300px] overflow-auto">
                                    <div className="mx-2 mt-4">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                value={search}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    setSearch(event.target.value);
                                                }}
                                            />

                                            <img
                                                src="/img/risk-assessment/ri_search-line-small.svg"
                                                alt="Search Icon"
                                                className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                            />
                                        </div>
                                    </div>
                                    {filtered_frameworks_?.length ? (
                                        <div className="!p-2 flex flex-col min-w-[224px]">
                                            {filtered_frameworks_?.map((framework, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setLocalRiskIdentificationStates((prev) => ({
                                                            ...prev,
                                                            frameworks: [...prev.frameworks, framework]?.filter(
                                                                Boolean
                                                            ),
                                                        }));
                                                    }}
                                                    sx={{
                                                        color: localRiskIdentificationStates?.frameworks?.includes(
                                                            framework
                                                        )
                                                            ? '#FFFFFF'
                                                            : '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '13px',
                                                        textTransform: 'capitalize',
                                                        padding: '6px 8px',
                                                        backgroundColor:
                                                            localRiskIdentificationStates?.frameworks?.includes(
                                                                framework
                                                            )
                                                                ? '#202D66'
                                                                : '',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                localRiskIdentificationStates?.frameworks?.includes(
                                                                    framework
                                                                )
                                                                    ? '#202D66'
                                                                    : '',
                                                            color: localRiskIdentificationStates?.frameworks?.includes(
                                                                framework
                                                            )
                                                                ? '#FFFFFF'
                                                                : '#64748B',
                                                        },
                                                    }}
                                                >
                                                    {framework}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                            Framework not found
                                        </p>
                                    )}
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B]">Additional Notes</p>

                    <textarea
                        placeholder="Comment"
                        value={localRiskIdentificationStates.additional_notes}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                additional_notes: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskFirstPageStates((prev) => ({
                                ...prev,
                                additional_notes: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                </div>
            </div>
        </>
    );
}
