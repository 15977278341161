import { useEffect, useState } from 'react';
//core components
// import ScanGroups from './Components/ScanGroups';
// import ScansDetails from './Components/ScanDetails';
import CircularLoader from 'components/Loaders/CircularLoader';

//redux
import { Box } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    AssignedAutomatedScan,
    GetAutomatedScanGroups,
    GetGroupScanResults,
} from 'store/actions/automatedScansActions';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import ScanGroupsModified from './Components/ScanGroupsModified';
import ScansViewModified from './Components/ScansViewModified';

const Scans = (props) => {
    const [loading, setLoading] = useState(false);

    const {
        GetAutomatedScanGroups,
        all_automated_scan_groups,
        view,
        changeView,
        GetGroupScanResults,
        category,
        setCategory,
        AssignedAutomatedScan,
        GetAllCompanyEmployees,
    } = props;

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    // functions
    const getAllAutomatedScanGroups = async () => {
        try {
            setLoading(true);
            const res = await GetAutomatedScanGroups();
            if (!res.success) {
                toast.error(res?.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const assignedAutomatedScan = async () => {
        const res = await AssignedAutomatedScan();
        if (!res.message) {
            toast.error(res.message);
        }
    };

    // async functions
    const getAllMerchantEmployees = async () => {
        const res = await GetAllCompanyEmployees();
        if (!res.message) {
            toast.error(res.message);
        }
    };
    // useEffect
    useEffect(() => {
        getAllAutomatedScanGroups();
    }, []);

    useEffect(() => {
        if (user_type !== 'auditor') {
            assignedAutomatedScan();
            getAllMerchantEmployees();
        }
    }, [user_type]);

    const handleChangeView = async (viewType, category) => {
        try {
            setLoading(true);
            changeView(viewType);
            setCategory(category);
            if (viewType === 'scans') {
                const res = await GetGroupScanResults();
                if (!res.success) {
                    toast.error('Something went wrong! Unable to fetch results.');
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const handleCategory = (category) => {
        setCategory(category);
    };
    return (
        <>
            {loading ? (
                <CircularLoader />
            ) : (
                <Box sx={{ height: 'calc(100vh - 150px)', px: '24px' }}>
                    {view === 'groups' ? (
                        // <ScanGroups
                        //     handleChangeView={handleChangeView}
                        //     groups={all_automated_scan_groups}
                        //     isScan={true}
                        //     category={category}
                        //     setCategory={setCategory}
                        //     handleCategory={handleCategory}
                        // />

                        <ScanGroupsModified
                            handleCategory={handleCategory}
                            category={category}
                            groups={all_automated_scan_groups}
                            handleChangeView={handleChangeView}
                        />
                    ) : (
                        // <ScansView
                        //     goBack={handleChangeView}
                        //     category={category}
                        //     isScan={true}
                        //     view={view}
                        //     loading={loading}
                        // />

                        <ScansViewModified
                            goBack={handleChangeView}
                            category={category}
                            isScan={true}
                            view={view}
                            loading={loading}
                        />
                    )}
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
    };
};

export default connect(mapStateToProps, {
    AssignedAutomatedScan,
    GetAutomatedScanGroups,
    GetAllCompanyEmployees,
    GetGroupScanResults,
})(Scans);
