/* eslint-disable no-unused-vars */

import { MoreHoriz } from '@mui/icons-material';
import AppTable from 'components/new_components/app-table2';
import { useEffect, useMemo, useState } from 'react';
import {
    RiskTemplatesTab,
    filterOptions,
    // categories,
    mitigationStatuses,
    plans,
    residual3x3Risks,
    residual5x5Risks,
    risk_sources,
} from './utils/constants';
// import RiskTitle from './components/RiskTitle';
import useSearch from 'hooks/useSearch';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllRiskAssessment, GetAssetOwners, GetRiskOwners } from 'store/actions/riskAssessmentActions';
import RunRiskTemplateDrawer from './/runRiskWithTemplate/RunRiskDrawer';
import ApproveRiskModal from './components/ApproveRiskModal';
import DisapproveRiskModal from './components/DisapprovedModal';
import DeleteModal from './components/DeleteModal';
import ExtendTimelineModal from './components/ExtendTimelineModal';
import MarkAsResolvedModal from './components/MarkAsResolvedModal';
import RiskAction from './components/RiskActions';
import RiskTableAction from './components/RiskTableAction';
import TopContent from './components/TopContent';
import ViewDetailsDrawer from './components/ViewDetailsDrawer';
import RunRiskDrawer from './runRisk/RunRiskDrawer';
import UpdateRiskAssessment from './UpdateRiskAssessment/RunRiskDrawer';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { OpenAutoRiskAction } from 'store/actions/riskAssessmentActions';
import MarkaDisApprovedModal from './components/MarkDisApprovedModal';
import { by5riskLevel, by3riskLevel } from 'utils';
import UploadCsvModal from './components/UploadCsvModal';
import { truncateText } from 'utils';

const NO_PER_PAGE = 10;

export default function RiskAssessmentRiskRegister(props) {
    const { setCurrentTab_ } = props;
    const [currentTab, setCurrentTab] = useState('all');
    const [category, setCategory] = useState([]);
    const [plan, setPlan] = useState([...plans]);
    const [residualRisk, setResidualRisk] = useState([...residual3x3Risks]);
    const [mitigationStatus, setMitigationStatus] = useState([...mitigationStatuses]);
    const [allData, setAllData] = useState([]);
    const [frameworks, setFrameworks] = useState([]);
    const [assetOwner, setAssetOwner] = useState('');
    const [riskOwner, setRiskOwner] = useState('');
    const [sources, setSources] = useState([...risk_sources]);
    const [riskViewMode, setRiskViewMode] = useState('residual risk');
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [planAnchorEl, setPlanAnchorEl] = useState(null);
    const [residualRiskAnchorEl, setResidualRiskAnchorEl] = useState(null);
    const [mitigationStatusAnchorEl, setMitigationStatusAnchorEl] = useState(null);
    const [frameworksAnchorEl, setFrameworksAnchorEl] = useState(null);
    const [assetOwnerAnchorEl, setAssetOwnerAnchorEl] = useState(null);
    const [riskOwnerAnchorEl, setRiskOwnerAnchorEl] = useState(null);
    const [sourcesAnchorEl, setSourcesAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [runRiskOpen, setRunRiskOpen] = useState(false);
    const [runRiskTemplateOpen, setRunRiskTemplateOpen] = useState(false);
    const [updateRiskOpen, setUpdateRiskOpen] = useState(false);
    const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [resolvedModal, setResolvedModal] = useState(false);
    const [approvalModal, setApprovalModal] = useState(false);
    const [disapprovalModal, setdisApprovalModal] = useState(false);
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const [template, setTemplate] = useState(null);
    const [ids, setIds] = useState([]);
    const [filters, setFilters] = useState([...filterOptions]);
    const [extendTimelineModal, setExtendTimelineModal] = useState(false);
    const [markDisapprovalModal, setMarkDisapprovalModal] = useState(false);
    const [uploadCsvModal, setUploadCsvModal] = useState(false);
    const [approvedComment, setApprovedComment] = useState('');
    const [riskType, setRiskType] = useState({
        name: 'All',
        value: 'all',
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [spoolAnchorEl, setSpoolAnchorEl] = useState(null);
    const [riskTypeAnchorEl, setRiskTypeAnchorEl] = useState(null);

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);

    const spoolOpenMenu = (e) => setSpoolAnchorEl(e.target);
    const spoolCloseMenu = () => setSpoolAnchorEl(null);

    const riskTypeOpenMenu = (e) => setRiskTypeAnchorEl(e.target);
    const riskTypeCloseMenu = () => setRiskTypeAnchorEl(null);
    const riskTypeChange = (type) => {
        setRiskType(type);
    };

    const openViewDetails = (details) => {
        setDetails(details);
        setViewDetailsOpen(true);
    };

    const openRiskTemplate = (payload) => {
        setTemplate(payload);
        setRunRiskTemplateOpen(true);
    };

    const closeViewDetails = () => {
        setDetails(null);
        setViewDetailsOpen(false);
    };

    const openDeleteModal = (details) => {
        setDetails(details);
        setDeleteModal(true);
    };

    const closeDeletemodal = () => {
        setDetails(null);
        setIds([]);
        setDeleteModal(false);
    };

    const openUpdateRiskModal = (details) => {
        setDetails(details);
        setUpdateRiskOpen(true);
    };

    const closeUpdateRiskMoodal = () => {
        setUpdateRiskOpen(false);
        setDetails(null);
    };

    const openReslovedModal = (details) => {
        setDetails(details);
        setResolvedModal(true);
    };

    const closeReslovedmodal = () => {
        setResolvedModal(false);
    };

    const openApprovalModal = (details) => {
        setDetails(details);
        setApprovalModal(true);
    };

    const closeApprovalModal = () => {
        setApprovalModal(false);
    };

    const openDisApprovalModal = () => {
        setDetails(details);
        setdisApprovalModal(true);
    };

    const closeDisApprovalModal = () => {
        setdisApprovalModal(false);
    };

    const openMarkDisApprovalModal = (details) => {
        setDetails(details);
        setMarkDisapprovalModal(true);
    };

    const closeMarkDisApprovalModal = () => {
        setMarkDisapprovalModal(false);
    };

    const openExtendTimelineModal = (details) => {
        setDetails(details);
        setExtendTimelineModal(true);
    };

    const closeExtendTimelineModal = () => {
        setExtendTimelineModal(false);
    };

    // redux
    const dispatch = useDispatch();
    const all_merchant_risk_assessment = useSelector(
        (state) => state?.riskAssessmentReducers.all_merchant_risk_assessment
    );
    const { risk_assessment_categories, get_asset_owners, open_auto_risk } = useSelector(
        (state) => state.riskAssessmentReducers
    );
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { data, handleSearch } = useSearch(all_merchant_risk_assessment, [
        'name',
        'mitigation_plan',
        'residual_risk_level_slug',
        'solution_owner',
    ]);

    const filtered_frameworks = merchant_info?.paid_standards
        ?.split(',')
        ?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        })
        ?.filter((standard) => standard !== 'soc2type2' && standard !== 'soc2 type2');

    // Async functions
    const getAllRiskAssestOwners = async () => {
        const res = await dispatch(GetAssetOwners());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllRiskOwners = async () => {
        const res = await dispatch(GetRiskOwners());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        const filtered_asset_owners = get_asset_owners
            ?.filter((risk) => risk)
            ?.filter(function (item, index, inputArray) {
                return inputArray.indexOf(item) == index;
            });
        const filtered_risk_owners = get_asset_owners
            ?.filter((risk) => risk)
            ?.filter(function (item, index, inputArray) {
                return inputArray.indexOf(item) == index;
            });
        setFrameworks(filtered_frameworks);
        setAssetOwner(filtered_asset_owners);
        setRiskOwner(filtered_risk_owners);
    }, [merchant_info]);

    const query_params = useMemo(() => {
        const params = {
            ...(currentTab && currentTab !== 'all' && currentTab !== 'auto' && { matrix: currentTab }),
        };
        return params;
    }, [currentTab]);

    // ...(userData &&
    //     userData?.user_type === "administrator" && {
    //       admin_id: userData?.superadmin,
    //     }),
    const getAllMerchantRiskAssessments = async () => {
        setLoading(true);
        const res = await dispatch(GetAllRiskAssessment(query_params));
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllMerchantRiskAssessments();
        getAllRiskOwners();
        getAllRiskAssestOwners();
    }, [query_params]);
    const columns = useMemo(
        () =>
            [
                {
                    title: (
                        <div className="flex items-center gap-5 ml-[-0.1rem] w-[200px]">
                            <div
                                onClick={() => {
                                    if (ids?.length) {
                                        setIds([]);
                                    } else {
                                        setIds(pageData?.map((data) => data?.id));
                                    }
                                }}
                            >
                                {ids?.length ? (
                                    <img
                                        src="/img/risk-assessment/checkbox2.svg"
                                        alt="Checkbox icon"
                                        className="object contain cursor-pointer"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object contain cursor-pointer"
                                    />
                                )}
                            </div>
                            <p className="w-[150px]">Risk subject</p>
                        </div>
                    ),
                    key: 'name',
                    render: (row) => (
                        <div className="flex items-center gap-5 w-[200px]">
                            <div
                                className="flex items-center gap-1"
                                onClick={() => {
                                    if (ids.includes(row.id)) {
                                        setIds(ids?.filter((id) => id !== row?.id));
                                    } else {
                                        setIds([...ids, row?.id]);
                                    }
                                }}
                            >
                                {ids.includes(row.id) ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain cursor-pointer"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain cursor-pointer"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">
                                    {truncateText(data?.name, '30')} {data?.name?.length > 30 ? '...' : ''}
                                </p>
                            </div>
                            {/* w-[280px] */}
                            <div className="flex items-center gap-1 w-[150px]">
                                {row?.risk_assessment_status?.toLowerCase() === 'resolved' ? null : row?.sub_status ===
                                  'timeline expired' ? (
                                    <div className="w-6 h-6 grid place-items-center">
                                        <img
                                            src="/img/risk-assessment/warning.svg"
                                            alt="Flag Icon"
                                            className="object-contain"
                                        />
                                    </div>
                                ) : null}

                                <p>{row?.name}</p>
                            </div>
                        </div>
                    ),
                },
                {
                    title: 'Risk Source',
                    key: 'source',
                    render: (row) => (
                        <p className="text-[#395BA9] font-medium text-sm text-capitalize">{row?.source}</p>
                    ),
                },
                {
                    title: 'Risk owner',
                    key: 'solution_owner',
                    render: (row) => (
                        <div>
                            {row?.solution_owner ? (
                                <p className="text-[#395BA9] font-medium text-sm">
                                    {row?.solution_owner_detail?.trim() !== ''
                                        ? row?.solution_owner_detail
                                        : row?.solution_owner}
                                </p>
                            ) : (
                                <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                                    --
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    title: <p className="whitespace-nowrap">Residual risk</p>,
                    key: 'residual_risk_level_slug',
                    render: (row) => (
                        <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                            {row?.residual_risk_level_slug && row?.residual_risk_level_slug !== 'NULL' ? (
                                <>
                                    <div
                                        className={`w-2 h-2 rounded-full`}
                                        style={{
                                            backgroundColor:
                                                row?.matrix === '3x3'
                                                    ? by3riskLevel(row?.residual_risk).color
                                                    : by5riskLevel(row?.residual_risk).color,
                                        }}
                                    />

                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {row?.matrix === '3x3'
                                            ? `${by3riskLevel(row?.residual_risk).type}`
                                            : `${by5riskLevel(row?.residual_risk).type}`}
                                    </p>
                                </>
                            ) : (
                                '--'
                            )}
                        </div>
                    ),
                },
                {
                    title: <p className="whitespace-nowrap">Inherent risk</p>,
                    key: 'inherent_risk_level_slug',
                    render: (row) => (
                        <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                            {row?.inherent_risk_level_slug ? (
                                <>
                                    <div
                                        className={`w-2 h-2 rounded-full ${
                                            row?.residual_risk_level_slug?.toLowerCase() === 'low' ||
                                            row?.residual_risk_level_slug?.toLowerCase() === 'very low'
                                                ? 'bg-[#55BE8B]'
                                                : row?.inherent_risk_level_slug?.toLowerCase() === 'medium'
                                                ? 'bg-[#D2A500]'
                                                : 'bg-[#FF5449]'
                                        }`}
                                        style={{
                                            backgroundColor:
                                                row?.matrix === '3x3'
                                                    ? by3riskLevel(row?.inherent_risk).color
                                                    : by5riskLevel(row?.inherent_risk).color,
                                        }}
                                    />

                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {row?.matrix === '3x3'
                                            ? `${by3riskLevel(row?.inherent_risk).type}`
                                            : `${by5riskLevel(row?.inherent_risk).type}`}
                                    </p>
                                </>
                            ) : (
                                '--'
                            )}
                        </div>
                    ),
                },
                {
                    title: <p className="whitespace-nowrap">Treatment plan</p>,
                    key: 'mitigation_plan',
                    render: (row) => (
                        <div className="flex flex-col gap-1">
                            <p className="text-[#395BA9] font-medium text-sm capitalize">{row?.mitigation_plan}</p>

                            {row?.risk_assessment_status?.toLowerCase() === 'resolved' ? null : row?.sub_status ===
                              'timeline expired' ? (
                                <CustomTooltip
                                    title="Risk remains unresolved after due mitigation timeline"
                                    placement="bottom"
                                >
                                    <p className="text-[#FF5449] font-normal text-xs capitalize">{row?.sub_status}</p>
                                </CustomTooltip>
                            ) : row?.sub_status ? (
                                <p className="text-[#64748B] font-normal text-xs">{row?.sub_status}</p>
                            ) : null}
                        </div>
                    ),
                },
                {
                    title: <p className="whitespace-nowrap">Treatment status</p>,
                    key: 'risk_assessment_status',
                    render: (row) => (
                        <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 bg-[#F1F5F9]">
                            {row?.risk_assessment_status && row?.risk_assessment_status !== 'no response' ? (
                                <>
                                    <div>
                                        {row?.risk_assessment_status?.toLowerCase() === 'approved' ? (
                                            <img
                                                src="/img/risk-assessment/thumbs-up.svg"
                                                alt="Approved Icon"
                                                className="object-contain"
                                            />
                                        ) : row?.risk_assessment_status?.toLowerCase() === 'resolved' ? (
                                            <img
                                                src="/img/risk-assessment/check-square-broken-risk-register.svg"
                                                alt="Resolved Icon"
                                                className="object-contain"
                                            />
                                        ) : row?.risk_assessment_status?.toLowerCase() === 'awaiting_approval' ||
                                          row?.risk_assessment_status?.toLowerCase() === 'disapproved' ? null : (
                                            <img
                                                src="/img/risk-assessment/warning-2.svg"
                                                alt="Not resolved Icon"
                                                className="object-contain"
                                            />
                                        )}
                                    </div>

                                    {row?.risk_assessment_status === 'awaiting_approval' ? (
                                        <CustomTooltip
                                            title="Risk mitigation plan needs to be approved by the management approver"
                                            placement="bottom"
                                        >
                                            <p
                                                className={`font-medium text-[13px] capitalize whitespace-nowrap ${
                                                    row?.risk_assessment_status?.toLowerCase() === 'approved'
                                                        ? 'text-[#395BA9]'
                                                        : row?.risk_assessment_status?.toLowerCase() === 'resolved'
                                                        ? 'text-[#37A372]'
                                                        : row?.risk_assessment_status?.toLowerCase() ===
                                                          'awaiting_approval'
                                                        ? 'text-[#475569]'
                                                        : row?.risk_assessment_status?.toLowerCase() === 'disapproved'
                                                        ? 'text-[#FF0000]'
                                                        : 'text-[#B28C00]'
                                                }`}
                                            >
                                                {row?.risk_assessment_status?.replace(/_/g, ' ')}
                                            </p>
                                        </CustomTooltip>
                                    ) : (
                                        <p
                                            className={`font-medium text-[13px] capitalize whitespace-nowrap ${
                                                row?.risk_assessment_status?.toLowerCase() === 'approved'
                                                    ? 'text-[#395BA9]'
                                                    : row?.risk_assessment_status?.toLowerCase() === 'resolved'
                                                    ? 'text-[#37A372]'
                                                    : row?.risk_assessment_status?.toLowerCase() === 'awaiting_approval'
                                                    ? 'text-[#475569]'
                                                    : row?.risk_assessment_status?.toLowerCase() === 'disapproved'
                                                    ? 'text-[#FF0000]'
                                                    : 'text-[#B28C00]'
                                            }`}
                                        >
                                            {row?.risk_assessment_status?.replace(/_/g, ' ')}
                                        </p>
                                    )}
                                </>
                            ) : (
                                '--'
                            )}
                        </div>
                    ),
                },
                {
                    title: <MoreHoriz />,
                    key: 'action',
                    render: (row) => (
                        <RiskTableAction
                            row={row}
                            openViewDetails={openViewDetails}
                            openDeleteModal={openDeleteModal}
                            openReslovedModal={openReslovedModal}
                            openExtendTimelineModal={openExtendTimelineModal}
                            openUpdateRiskModal={openUpdateRiskModal}
                            openApprovalModal={openApprovalModal}
                            openMarkDisApprovalModal={openMarkDisApprovalModal}
                        />
                    ),
                },
            ].filter((tableColumn) => {
                if (riskViewMode === 'residual risk') {
                    return tableColumn.key !== 'inherentRisk';
                } else if (riskViewMode === 'inherent risk') {
                    return tableColumn.key !== 'residualRisk';
                }
            }),
        [riskViewMode, ids]
    );
    useEffect(() => {
        if (risk_assessment_categories?.length) {
            setCategory(risk_assessment_categories);
        }
    }, [risk_assessment_categories]);

    useEffect(() => {
        if (currentTab === RiskTemplatesTab.ThreeByThree) {
            setResidualRisk([...residual3x3Risks]);
        } else {
            setResidualRisk([...residual5x5Risks]);
        }
    }, [currentTab]);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = [...(data || [])];

        if (open_auto_risk && currentTab === 'auto') {
            filteredData = filteredData?.filter((item) => item?.source === 'auto');
        } else {
            if (filteredData?.length > 0 && filters?.includes('category')) {
                const category_ids = category?.map((category) => category?.id);
                filteredData = filteredData?.filter((item) => category_ids?.includes(item?.category));
            }
            if (filteredData?.length > 0 && filters?.includes('plan')) {
                filteredData = filteredData?.filter((item) => plan?.includes(item?.mitigation_plan));
            }

            if (filteredData?.length > 0 && riskType?.name !== 'All') {
                filteredData = filteredData?.filter((item) => item[riskType?.value]);
            }

            if (filteredData?.length > 0 && filters?.includes('framework')) {
                filteredData = filteredData?.filter((item) => {
                    const frameworks_list =
                        typeof item?.frameworks === 'string' ? item?.frameworks?.split(',') : item?.frameworks;

                    return frameworks_list?.length
                        ? frameworks_list?.find((framework) => frameworks?.includes(framework))
                        : '';
                });
            }
            // if (filteredData?.length > 0 && filters?.includes('risk')) {
            //     filteredData = filteredData?.filter((item) => residualRisk?.includes(item?.inherent_risk_level_slug));
            // }

            if (filteredData?.length > 0 && filters?.includes('mitigation_status')) {
                filteredData = filteredData?.filter((item) => mitigationStatus?.includes(item?.risk_assessment_status));
            }

            if (filteredData?.length > 0 && filters?.includes('source')) {
                filteredData = filteredData?.filter((item) => sources?.includes(item?.source));
            }
            if (filteredData?.length > 0 && filters?.includes('asset_owner')) {
                filteredData = filteredData?.filter((item) => assetOwner?.includes(item?.solution_owner));
            }
        }

        const new_data = filteredData?.length ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : [];
        setAllData(filteredData);
        setPageData(new_data);
    }, [
        page,
        data,
        currentTab,
        category,
        plan,
        residualRisk,
        riskType,
        residualRisk,
        mitigationStatus,
        sources,
        riskOwner,
        filters,
        frameworks,
        assetOwner,
        open_auto_risk,
    ]);
    const handlePageChange = (page) => {
        setPage(page);
    };
    const handleCloseAutoRisk = () => {
        dispatch(OpenAutoRiskAction(false));
    };
    const auto_risks = data?.filter((risk) => risk?.source === 'auto');

    return (
        <>
            <div
                className="mx-[26px] bg-[#FFFBEB] !border !border-[#F2C021] !rounded !py-4 !px-6 flex items-center justify-between mt-[1rem] gap-6"
                hidden={!open_auto_risk || auto_risks?.length === 0}
            >
                <div className="flex items-center gap-3">
                    <div className="flex items-center gap-2">
                        <img
                            src="/img/risk-assessment/info-circle.svg"
                            alt="Attention Icon"
                            className="object-contain"
                        />

                        <p className="font-medium text-[15px] text-[#755B00]">
                            <span className="font-bold">{auto_risks?.length}</span> infrastructure risks detected!
                            Review them now in your risk register or filter by{' '}
                            <span className="font-bold">origin::Auto added</span> to view.
                        </p>
                    </div>

                    <button
                        className="text-[#755B00] bg-[#FFFBEB] font-medium text-sm !border !border-[#755B00] !rounded !py-1 !px-2 transition-all hover:brightness-90 whitespace-nowrap"
                        onClick={() => setCurrentTab('auto')}
                    >
                        Review risk
                    </button>
                </div>

                <button className="transition-all hover:scale-90" onClick={handleCloseAutoRisk}>
                    <img src="/img/risk-assessment/close-headsup.svg" alt="Close Icon" className="object-contain" />
                </button>
            </div>
            <div className="bg-white rounded my-[2rem] mx-[1.5rem] flex flex-col gap-4 !py-6 !px-4">
                <TopContent
                    riskTypeAnchorEl={riskTypeAnchorEl}
                    riskType={riskType}
                    riskTypeChange={riskTypeChange}
                    spoolCloseMenu={spoolCloseMenu}
                    spoolOpenMenu={spoolOpenMenu}
                    setCurrentTab={setCurrentTab}
                    anchorEl={anchorEl}
                    closeMenu={closeMenu}
                    currentTab={currentTab}
                    riskTypeCloseMenu={riskTypeCloseMenu}
                    riskTypeOpenMenu={riskTypeOpenMenu}
                    spoolAnchorEl={spoolAnchorEl}
                    openMenu={openMenu}
                    setRunRiskOpen={setRunRiskOpen}
                    openRiskTemplate={openRiskTemplate}
                    ids={ids}
                    openReslovedModal={openReslovedModal}
                    openDeleteModal={openDeleteModal}
                    pageData={pageData}
                    openUpdateRiskModal={openUpdateRiskModal}
                    openCsvModal={() => setUploadCsvModal(true)}
                />
                {/* <div className="flex justify-between items-center px-3 gap-5 flex-wrap">
                    <RiskTitle onSearch={handleSearch} /> */}
                <RiskAction
                    categoryAnchorEl={categoryAnchorEl}
                    setCategoryAnchorEl={setCategoryAnchorEl}
                    category={category}
                    setCategory={setCategory}
                    setPlanAnchorEl={setPlanAnchorEl}
                    planAnchorEl={planAnchorEl}
                    plan={plan}
                    setPlan={setPlan}
                    currentTab={currentTab}
                    setResidualRiskAnchorEl={setResidualRiskAnchorEl}
                    residualRiskAnchorEl={residualRiskAnchorEl}
                    setResidualRisk={setResidualRisk}
                    residualRisk={residualRisk}
                    mitigationStatus={mitigationStatus}
                    setMitigationStatus={setMitigationStatus}
                    mitigationStatusAnchorEl={mitigationStatusAnchorEl}
                    setMitigationStatusAnchorEl={setMitigationStatusAnchorEl}
                    frameworks={frameworks}
                    setFrameworks={setFrameworks}
                    frameworksAnchorEl={frameworksAnchorEl}
                    setFrameworksAnchorEl={setFrameworksAnchorEl}
                    setActionAnchorEl={setActionAnchorEl}
                    actionAnchorEl={actionAnchorEl}
                    filters={filters}
                    setFilters={setFilters}
                    sources={sources}
                    setSources={setSources}
                    sourcesAnchorEl={sourcesAnchorEl}
                    setSourcesAnchorEl={setSourcesAnchorEl}
                    riskOwner={riskOwner}
                    setRiskOwner={setRiskOwner}
                    riskOwnerAnchorEl={riskOwnerAnchorEl}
                    setRiskOwnerAnchorEl={setRiskOwnerAnchorEl}
                    assetOwner={assetOwner}
                    setAssetOwner={setAssetOwner}
                    assetOwnerAnchorEl={assetOwnerAnchorEl}
                    setAssetOwnerAnchorEl={setAssetOwnerAnchorEl}
                    onSearch={handleSearch}
                />
                {/* </div> */}
                <AppTable
                    columns={columns}
                    data={pageData}
                    sorter={(a, b) => b?.id - a?.id}
                    loading={loading}
                    // dataLength={data?.length}
                    dataLength={allData?.length}
                    noPerPage={NO_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    checked_row={ids}
                    showTitleBar={false}
                />
                <RunRiskDrawer
                    open={runRiskOpen}
                    handleClose={() => {
                        setDetails(null);
                        setRunRiskOpen(false);
                    }}
                    params={query_params}
                    setCurrentTab_={setCurrentTab_}
                />
                <RunRiskTemplateDrawer
                    open={runRiskTemplateOpen}
                    handleClose={() => {
                        setDetails(null);
                        setTemplate(null);
                        setRunRiskTemplateOpen(false);
                    }}
                    params={query_params}
                    template={template}
                />
                <UpdateRiskAssessment
                    open={updateRiskOpen}
                    handleClose={closeUpdateRiskMoodal}
                    details={details}
                    params={query_params}
                />
                <ViewDetailsDrawer
                    open={viewDetailsOpen}
                    handleClose={closeViewDetails}
                    details={details}
                    openReslovedModal={openReslovedModal}
                />
                <DeleteModal
                    open={deleteModal}
                    handleClose={closeDeletemodal}
                    id={details?.id}
                    ids={ids}
                    params={query_params}
                />
                <MarkAsResolvedModal
                    open={resolvedModal}
                    handleClose={closeReslovedmodal}
                    id={details?.id}
                    ids={ids}
                    params={query_params}
                />
                <ExtendTimelineModal
                    open={extendTimelineModal}
                    handleClose={closeExtendTimelineModal}
                    details={details}
                    params={query_params}
                />
                <ApproveRiskModal open={approvalModal} handleClose={closeApprovalModal} id={details?.id} />
                <DisapproveRiskModal
                    open={disapprovalModal}
                    handleClose={closeDisApprovalModal}
                    id={details?.id}
                    approvedComment={approvedComment}
                />
                <MarkaDisApprovedModal
                    open={markDisapprovalModal}
                    handleClose={closeMarkDisApprovalModal}
                    openDisApprovalModal={openDisApprovalModal}
                    approvedComment={approvedComment}
                    setApprovedComment={setApprovedComment}
                />
                <UploadCsvModal open={uploadCsvModal} handleClose={() => setUploadCsvModal(false)} />
            </div>
        </>
    );
}
