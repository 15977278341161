import React from 'react';
import Slider from 'react-slick';
import './carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ slides, handleOpen, type, data, view, containerStyles }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 8000,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <></>,
        prevArrow: <></>,
    };
    const handleOpenModal = (slide) => {
        const filtered_data = Object?.keys(data)
            ?.map((item) => {
                return {
                    key: item,
                    value: data[item],
                };
            })
            ?.filter((item) => item?.value?.length > 0)
            ?.find((item) => item?.value.includes(slide));
        handleOpen(slide, type, filtered_data?.key);
    };
    return (
        <div className={`w-full h-[36px] ${containerStyles || ''}`}>
            <Slider {...settings}>
                {slides.map((slide, index) => {
                    const name =
                        slide?.title || slide?.overview || slide?.common_name || slide?.name || slide?.market_place;
                    return (
                        <div key={index} className="!h-full">
                            <div
                                onClick={() => handleOpenModal(slide)}
                                className="cursor-pointer xl:block md:hidden !h-full"
                            >
                                {view === 'compliance' ? (
                                    <h4 className="text-[#475569] text-[13px] font-medium">
                                        {name?.substring(0, 200)}
                                        {name?.length > 200 && '...'}{' '}
                                    </h4>
                                ) : (
                                    <h4 className="text-[#475569] text-[13px] font-medium">
                                        {name?.substring(0, 60)}
                                        {name?.length > 60 && '...'}{' '}
                                    </h4>
                                )}
                            </div>

                            <div onClick={() => handleOpenModal(slide)} className="cursor-pointer xl:hidden md:block">
                                {view === 'compliance' ? (
                                    <h4 className="text-[#475569] text-[13px] font-medium">
                                        {name?.substring(0, 150)}
                                        {name?.length > 150 && '...'}{' '}
                                    </h4>
                                ) : (
                                    <h4 className="text-[#475569] text-[13px] font-medium">
                                        {name?.substring(0, 50)}
                                        {name?.length > 50 && '...'}{' '}
                                    </h4>
                                )}
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default Carousel;
