//  A U T H
export const USER_DETAILS = 'USER_DETAILS';
export const USER_TYPE = 'USER_TYPE';
export const USER_ID = 'USER_ID';
export const COMPANY_ID = 'COMPANY_ID';
export const COMPANY_DETAILS = 'COMPANY_DETAILS';
export const USER_PRICING_PLANS = 'USER_PRICING_PLANS';
export const FIRST_LOGIN = 'FIRST_LOGIN';

// G E N E R A L
export const USER_INFO = 'USER_INFO';
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const RISK_PERMISSION = 'RISK_PERMISSION';
export const RISK_CURRENT_PERMISSION = 'RISK_CURRENT_PERMISSION';
export const RISK_SELF_PORTAL_EMAIL = 'RISK_SELF_PORTAL_EMAIL';
export const RISK_SELF_PORTAL_TOKEN = 'RISK_SELF_PORTAL_TOKEN';
export const RISK_SELF_SERVICE = 'RISK_SELF_SERVICE';
export const AUDITOR_INFO = 'AUDITOR_INFO';
export const GET_ADDONS = 'GET_ADDONS';
export const GET_SMART_INTEL = 'GET_SMART_INTEL';
// M E R C H A N T S
export const MERCHANT_INFO = 'MERCHANT_INFO';
export const ALL_AUDITOR_MERCHANTS = 'ALL_AUDITOR_MERCHANTS';
export const ALL_ACTIVITIES = 'ALL_ACTIVITIES';
export const ALL_MERCHANT_FORMS = 'ALL_MERCHANT_FORMS';
export const MERCHANT_SERVERS = 'MERCHANT_SERVERS';
export const OVERVIEW_SUMMARY = 'OVERVIEW_SUMMARY';
export const PCIDSS4POINT0_OVERVIEW_SUMMARY = 'PCIDSS4POINT0_OVERVIEW_SUMMARY';
export const COMPLIANCE_SUMMARY = 'COMPLIANCE_SUMMARY';
export const PCIDSS4POINT0_COMPLIANCE_SUMMARY = 'PCIDSS4POINT0_COMPLIANCE_SUMMARY';
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS';
export const MERCHANT_ASVSCANS_REQUESTS = 'MERCHANT_ASVSCANS_REQUESTS';
export const ASV_REPORTS_BY_ID = 'ASV_REPORTS_BY_ID';
export const MERCHANT_PENTESTS_REQUESTS = 'MERCHANT_PENTESTS_REQUESTS';
export const PENTESTS_REPORT_BY_ID = 'PENTESTS_REPORT_BY_ID';
export const ALL_SCANS_EXCEPTIONS = 'ALL_SCANS_EXCEPTIONS';
export const ALL_MERCHANT_PEOPLE = 'ALL_MERCHANT_PEOPLE';
export const ALL_COMPUTERS = 'ALL_COMPUTERS';
export const ALL_APPLICATION_SETUPS = 'ALL_APPLICATION_SETUPS';
export const GITHUB_ACCOUNTS = 'GITHUB_ACCOUNTS';
export const ALL_MERCHANT_NDPR_DOCUMENTS = 'ALL_MERCHANT_NDPR_DOCUMENTS';
export const MERCHANT_NDPR_DOCUMENT_VERSIONS = 'MERCHANT_NDPR_DOCUMENT_VERSIONS';
export const ALL_ADMIN_DOCUMENTS_BY_TAGS = 'ALL_ADMIN_DOCUMENTS_BY_TAGS';
export const ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS = 'ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS';
export const ALL_VULNERABILITY_SCANS = 'ALL_VULNERABILITY_SCANS';
export const ALL_ADMIN_NDPR_FORMS = 'ALL_ADMIN_NDPR_FORMS';
export const ALL_ADMIN_KDPA_FORMS = 'ALL_ADMIN_KDPA_FORMS';
export const ALL_ADMIN_GDPA_FORMS = 'ALL_ADMIN_GDPA_FORMS';
export const NDPR_SUMMARY_BY_CATEORY = 'NDPR_SUMMARY_BY_CATEORY';
export const KDPA_SUMMARY_BY_CATEGORY = 'KDPA_SUMMARY_BY_CATEGORY';
export const GDPA_SUMMARY_BY_CATEGORY = 'GDPA_SUMMARY_BY_CATEGORY';
export const NDPR_GENERAL_SUMMARY = 'NDPR_GENERAL_SUMMARY';
export const KDPA_GENERAL_SUMMARY = 'KDPA_GENERAL_SUMMARY';
export const GDPA_GENERAL_SUMMARY = 'GDPA_GENERAL_SUMMARY';
export const ISO_SUMMARY_BY_ANNEX = 'ISO_SUMMARY_BY_ANNEX';
export const ISO_GENERAL_SUMMARY = 'ISO_GENERAL_SUMMARY';
export const ISO_TWO_SUMMARY_BY_ANNEX = 'ISO_TWO_SUMMARY_BY_ANNEX';
export const PCISLC_SUMMARY_BY_CATEGORY = 'PCISLC_SUMMARY_BY_CATEGORY';
export const ISO_TWO_GENERAL_SUMMARY = 'ISO_TWO_GENERAL_SUMMARY';
export const CONTINUITY_GENERAL_SUMMARY = 'CONTINUITY_GENERAL_SUMMARY';
export const ALL_MERCHANT_INFRASTRUCTURES = 'ALL_MERCHANT_INFRASTRUCTURES';
export const MERCHANT_INFRASTRUCTURE_INFO = 'MERCHANT_INFRASTRUCTURE_INFO';
export const ONE_MERCHANT_PERSON = 'ONE_MERCHANT_PERSON';
export const ALL_MERCHANT_ONBOARDING_ITEMS = 'ALL_MERCHANT_ONBOARDING_ITEMS';
export const ALL_MERCHANT_ONBOARDING_RESOURCE = 'ALL_MERCHANT_ONBOARDING_RESOURCE';
export const ALL_MERCHANT_RISK_ASSESSMENTS = 'ALL_MERCHANT_RISK_ASSESSMENTS';
export const RISK_ASSESSMENT_SUMMARY = 'RISK_ASSESSMENT_SUMMARY';
export const FILTERED_MERCHANT_RISK_ASSESSMENTS = 'FILTERED_MERCHANT_RISK_ASSESSMENTS';
export const ALL_MERCHANT_INTELLIGENCE_REPORT = 'ALL_MERCHANT_INTELLIGENCE_REPORT';
export const FILTERED_MERCHANT_INTELLIGENCE_REPORT = 'FILTERED_MERCHANT_INTELLIGENCE_REPORT';
export const SPOOL_RISK_ASSESSMENT = 'SPOOL_RISK_ASSESSMENT';
export const SPOOL_INTELLIGENCE_REPORT = 'SPOOL_INTELLIGENCE_REPORT';
export const SPOOL_REPORTS = 'SPOOL_REPORTS';
export const ALL_SPOOL_REPORTS = 'ALL_SPOOL_REPORTS';
export const ALL_ONBOARDING_ITEMS_FOR_MERCHANTS = 'ALL_ONBOARDING_ITEMS_FOR_MERCHANTS';
export const ALL_MERCHANT_EXEMPTED_DOCUMENTS_BY_STANDARD = 'ALL_MERCHANT_EXEMPTED_DOCUMENTS_BY_STANDARD';
export const ALL_MERCHANT_EXEMPTED_FORMS_BY_STANDARD = 'ALL_MERCHANT_EXEMPTED_FORMS_BY_STANDARD';
export const ALL_MERCHANT_PCIDSS_EXEMPTED_FORMS_BY_SUBREQUIREMENT =
    'ALL_MERCHANT_PCIDSS_EXEMPTED_FORMS_BY_SUBREQUIREMENT';
export const ALL_CODE_REVIEW = 'OALL_CODE_REVIEW';
export const ALL_SURVEY_RESPONSE = 'ALL_SURVEY_RESPONSE';
export const USER_SURVEY_RESPONSE = 'USER_SURVEY_RESPONSE';
export const CII_GENERAL_SUMMARY = 'CII_GENERAL_SUMMARY';
export const PCISLC_GENERAL_SUMMARY = 'PCISLC_GENERAL_SUMMARY';
export const ISO9001_GENERAL_SUMMARY = 'ISO9001_GENERAL_SUMMARY';
export const ISO9901_SUMMARY_BY_CATEGORY = 'ISO9901_SUMMARY_BY_CATEGORY';

// I S O 2 7 0 0 1 + I S O 2 7 0 3 5
export const ALL_TECH_SEC_METRICS = 'ALL_TECH_SEC_METRICS';
export const TECH_SEC_GENERAL_SUMMARY = 'TECH_SEC_GENERAL_SUMMARY';
export const TECH_SEC_SUMMARY_BY_TECHSEC = 'TECH_SEC_SUMMARY_BY_TECHSEC';
export const ALL_TECH_SEC_GROUPS = 'ALL_TECH_SEC_GROUPS';
export const ALL_TECH_SEC_DOCUMENTS = 'ALL_TECH_SEC_DOCUMENTS';
export const ALL_TECH_SEC_FORMS = 'ALL_TECH_SEC_FORMS';
export const ALL_TECH_SEC_CATEGORIES = 'ALL_TECH_SEC_CATEGORIES';
export const ALL_MERCHANT_EMPLOYEES = 'ALL_MERCHANT_EMPLOYEES';

// A U D I T O R S
export const ALL_PORTFOLIOS = 'ALL_PORTFOLIOS';
export const AUDITOR_CERTIFICATIONS = 'AUDITOR_CERTIFICATIONS';
export const ONE_AUDITOR = 'ONE_AUDITOR';
export const AUDITOR_MERCHANTS = 'AUDITOR_MERCHANTS';
export const AUDITOR_MERCHANT_DOC_MESSAGES = 'AUDITOR_MERCHANT_DOC_MESSAGES';
export const AUDITOR_MERCHANT_DOCUMENTS = 'AUDITOR_MERCHANT_DOCUMENTS';
export const AUDITOR_MERCHANT_DOCUMENTS_VERSIONS = 'AUDITOR_MERCHANT_DOCUMENTS_VERSIONS';
export const AUDITOR_MERCHANT_COMPLIANCE_SUMMARY = 'AUDITOR_MERCHANT_COMPLIANCE_SUMMARY';
export const AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY = 'AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY';
export const AUDITOR_MERCHANT_FORMS = 'AUDITOR_MERCHANT_FORMS';
export const MERCHANT_PENTEST_REQUESTS = 'MERCHANT_PENTEST_REQUESTS';
export const MERCHANT_ASV_SCANS = 'MERCHANT_ASV_SCANS';
export const MERCHANT_SERVER_RESULT_SCAN = 'MERCHANT_SERVER_RESULT_SCAN';
export const MERCHANT_PENTEST_UPLOADS = 'MERCHANT_PENTEST_UPLOADS';
export const AUDITOR_MERCHANT_ASVSCANS_UPLOADS = 'AUDITOR_MERCHANT_ASVSCANS_UPLOADS';
export const ALL_MERCHANT_SCANS_EXCEPTIONS = 'ALL_MERCHANT_SCANS_EXCEPTIONS';
export const ALL_MERCHANT_EXEMPTED_SCANS = 'ALL_MERCHANT_EXEMPTED_SCANS';
export const AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY = 'AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY';
export const SOC2_GENERAL_SUMMARY = 'SOC2_GENERAL_SUMMARY';
export const SOC2_SUMMARY_BY_CONTROL = 'SOC2_SUMMARY_BY_CONTROL';
export const AUDITOR_MERCHANT_VULNERABILITY_SCANS = 'AUDITOR_MERCHANT_VULNERABILITY_SCANS';
export const AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY = 'AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY';
export const AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY = 'AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY';
export const APPROVED_USER = 'APPROVED_USER';
export const ALL_APPROVED_USER = 'ALL_APPROVED_USER';
export const FRAUD_SETTINGS = 'FRAUD_SETTINGS';
export const ALL_KYC_RESULTS = 'ALL_KYC_RESULT';
export const KYC_RESULTS = 'KYC_RESULTS';
export const ALL_FRAUD_MONITORING = 'ALL_FRAUD_MONITORING';
export const FRAUD_SUMMARY = 'FRAUD_SUMMARY';
export const ALL_COUNTRIES = 'ALL_COUNTRIES';
export const ALL_SOURCES = 'ALL_SOURCES';
export const ALL_SOURCES_LIST = 'ALL_SOURCES_LIST';
export const ALL_EVIDENCE_EXCEPTIONS = 'ALL_EVIDENCE_EXCEPTIONS';

// A D M I N
export const ALL_AUDITORS = 'ALL_AUDITORS';
export const ALL_MERCHANTS = 'ALL_MERCHANTS';
export const ALL_CATEGORIES = 'ALL_CATEGORIES';
export const ALL_GDPA_CATEGORIES = 'ALL_GDPA_CATEGORIES';
export const ALL_KDPA_CATEGORIES = 'ALL_KDPA_CATEGORIES';
export const ALL_NDPR_DOCUMENTS = 'ALL_NDPR_DOCUMENTS';
export const ALL_KDPA_DOCUMENTS = 'ALL_KDPA_DOCUMENTS';
export const ALL_GDPA_DOCUMENTS = 'ALL_GDPA_DOCUMENTS';
export const ALL_ANNEX_CATEGORIES = 'ALL_ANNEX_CATEGORIES';
export const ALL_ANNEXES = 'ALL_ANNEXES';
export const ALL_ANNEXES_TWO = 'ALL_ANNEXES_TWO';
export const ALL_ANNEXES_NEW = 'ALL_ANNEXES_NEW';
export const ALL_ANNEXTWO_CATEGORIES = 'ALL_ANNEXTWO_CATEGORIES';
export const ALL_ANNEXNEW_CATEGORIES = 'ALL_ANNEXNEW_CATEGORIES';
export const ALL_ISO27001_DOCUMENTS = 'ALL_ISO27001_DOCUMENTS';
export const ALL_ISO27001_2_DOCUMENTS = 'ALL_ISO27001_2_DOCUMENTS';
export const ALL_ISONEW_DOCUMENTS = 'ALL_ISONEW_DOCUMENTS';
export const ALL_ADMIN_DOCUMENTS_BY_ALTTAGS = 'ALL_ADMIN_DOCUMENTS_BY_ALTTAGS';
export const ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS = 'ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS';
export const ALL_ADMIN_ISO_FORMS = 'ALL_ADMIN_ISO_FORMS';
export const ALL_ADMIN_ISOTWO_FORMS = 'ALL_ADMIN_ISOTWO_FORMS';
export const ALL_ADMIN_ISONEW_FORMS = 'ALL_ADMIN_ISONEW_FORMS';
export const ALL_ONBOARDING_ITEMS = 'ALL_ONBOARDING_ITEMS';
export const ALL_THREATS = 'ALL_THREATS';
export const ALL_THREATS_CATEGORIES = 'ALL_THREATS_CATEGORIES';
export const ALL_RISK_CATEGORIES = 'ALL_RISK_CATEGORIES';
export const ALL_VULNERABILITIES = 'ALL_VULNERABILITIES';
export const ALL_CONTROLS = 'ALL_CONTROLS';
export const ALL_LIFECYCLE = 'ALL_LIFECYCLE';
export const ALL_CONTROL_ObJECTIVE_METRICS = 'ALL_CONTROL_ObJECTIVE_METRICS';
export const ALL_SUPPORT_USERS = 'ALL_SUPPORT_USERS';
export const ALL_RELEASES = 'ALL_RELEASES';
export const ALL_DOCUMENTS_BY_REQUIREMENT_ID = 'ALL_DOCUMENTS_BY_REQUIREMENT_ID';
export const ALL_DOCUMENTS_BY_CONTROL_ID = 'ALL_DOCUMENTS_BY_CONTROL_ID';
export const ALL_INTEGRATIONS_GROUPS = 'ALL_INTEGRATIONS_GROUPS';
export const ALL_INTEGRATIONS_BY_GROUP = 'ALL_INTEGRATIONS_BY_GROUP';
export const INTEGRATION_BY_ID = 'INTEGRATION_BY_ID';
export const ALL_SUBCRITERIA_DOCUMENT = 'ALL_SUBCRITERIA_DOCUMENT';
export const ALL_SUBCRITERIA_FORMS = 'ALL_SUBCRITERIA_FORMS';
export const MERCHANT_BY_ID = 'MERCHANT_BY_ID';
export const ALL_PUSH_NOTIFICATIONS = 'ALL_PUSH_NOTIFICATIONS';
export const ALL_USERS = 'ALL_USERS';
export const ALL_SURVEY = 'ALL_SURVEY';
export const ALL_PENTESTS = 'ALL_PENTESTS';
export const MERCHANT_EXTRA_DOCS = 'MERCHANT_EXTRA_DOCS';
export const ALL_QUALITY_MANAGEMENT = 'ALL_QUALITY_MANAGEMENT';
export const ALL_QUALITY_MANAGEMENT_METRICS = 'ALL_QUALITY_MANAGEMENT_METRICS';
export const ALL_ADMIN_DOCUMENTS_QUALITY_MANAGEMENT = 'ALL_ADMIN_DOCUMENTS_QUALITY_MANAGEMENT';
export const ALL_ADMIN_EVIDENCE_BY_QUALITY_MANAGEMENT = 'ALL_ADMIN_EVIDENCE_BY_QUALITY_MANAGEMENT';

//Data room
export const ADMIN_UPLOADED_DOCS = 'ADMIN_UPLOADED_DOCS';

//A R T I C L E S
export const ALL_ARTICLE_HEADER = 'ALL_ARTICLE_HEADER';
export const ALL_ARTICLES = 'ALL_ARTICLES';
export const ALL_MERCHANT_ARTICLES = 'ALL_MERCHANT_ARTICLES';
export const ONE_ARTICLE = 'ONE_ARTICLE';
export const ONE_ARTICLE_HELPFUL = 'ONE_ARTICLE_HELPFUL';
export const ONE_ARTICLE_REQUEST = 'ONE_ARTICLE_REQUEST';
export const ARTICLES_REQUEST = 'ARTICLES_REQUEST';

// S U B S C R I P T I O N S
export const ALL_PLANS = 'ALL_PLANS';
export const ALL_MANAGEMENT_CLAUSES = 'ALL_MANAGEMENT_CLAUSES';
export const ALL_MANAGEMENT_CLAUSES_TWO = 'ALL_MANAGEMENT_CLAUSES_TWO';
export const ALL_MANAGEMENT_CLAUSES_NEW = 'ALL_MANAGEMENT_CLAUSES_NEW';
export const ALL_CYBERSECURITIES = 'ALL_CYBERSECURITIES';
export const ALL_PROJECT_MANAGEMENT = 'ALL_PROJECT_MANAGEMENT';
export const ALL_CLOUD_SECURITIES = 'ALL_CLOUD_SECURITIES';
export const ALL_FEATURES = 'ALL_FEATURES';

// C O M P L I A N C E
export const ALL_ADMIN_PCIDSS_FORMS = 'ALL_ADMIN_PCIDSS_FORMS';
export const ALL_FORMS_BY_TAG = 'ALL_FORMS_BY_TAG';
export const ALL_DOCUMENTS_BY_TAG = 'ALL_DOCUMENTS_BY_TAG';
export const ALL_ADMIN_PCIDSS4POINT0_FORMS = 'ALL_ADMIN_PCIDSS4POINT0_FORMS';
export const ALL_ADMIN_BLOCKCHAIN_FORMS = 'ALL_ADMIN_BLOCKCHAIN_FORMS';
export const ALL_REQUIREMENTS = 'ALL_REQUIREMENTS';
export const ONE_REQUIREMENT = 'ONE_REQUIREMENT';
export const ALL_PCIDSS4POINT0_REQUIREMENTS = 'ALL_PCIDSS4POINT0_REQUIREMENTS';
export const ONE_PCIDSS4POINT0_REQUIREMENT = 'ONE_PCIDSS4POINT0_REQUIREMENT';
export const ALL_ADMIN_DOCUMENTS_BY_SUBREQ = 'ALL_DOCUMENTS';
export const ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ = 'ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ';
export const ALL_FORM_FIELDS = 'ALL_FORM_FIELDS';
export const SERVER_SCAN_RESULTS = 'SERVER_SCAN_RESULTS';
export const ALL_ADMIN_DOCUMENTS_BY_CONTROL = 'ALL_ADMIN_DOCUMENTS_BY_CONTROL';
export const ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL = 'ALL_ADMIN_DOCUMENTS_BY_COMMON_CONTROL';
export const ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE = 'ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE';
export const ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL = 'ALL_ADMIN_EVIDENCE_BY_COMMON_CONTROL';
export const ALL_ADMIN_EVIDENCE_BY_CONTROL = 'ALL_ADMIN_EVIDENCE_BY_CONTROL';
export const ALL_ADMIN_EVIDENCE_BY_SUB_REQUIREMENT = 'ALL_ADMIN_EVIDENCE_BY_SUB_REQUIREMENT';
export const ALL_ADMIN_EVIDENCE_BY_MANAGEMENT = 'ALL_ADMIN_EVIDENCE_BY_MANAGEMENT';
export const ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE = 'ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE';
export const ALL_ADMIN_FORMS_BY_CONTROL = 'ALL_ADMIN_FORMS_BY_CONTROL';
export const DOCUMENTS_BY_SUBCRITERIA = 'DOCUMENTS_BY_SUBCRITERIA';
export const ALL_CRITERIA = 'ALL_CRITERIA';
export const ALL_CONDITIONAL_ITEMS = 'ALL_CONDITIONAL_ITEMS';
export const MERCHANT_DOCUMENTS_BY_TAG = 'MERCHANT_DOCUMENTS_BY_TAG';
export const ALL_CONDITIONAL_ANSWER = 'ALL_CONDITIONAL_ANSWER';
export const ALL_CRITERIA_SUMMARY = 'ALL_CRITERIA_SUMMARY';
export const ALL_BLOCKCHAIN_EXCEPTION = 'ALL_BLOCKCHAIN_EXCEPTION';
export const CONDITIONAL_BY_ID = 'CONDITIONAL_BY_ID';
export const ALL_TOOLS_ITEMS = 'ALL_TOOLS_ITEMS';
export const ALL_BACKGROUND_CHECK_TOOLS = 'ALL_BACKGROUND_CHECK_TOOLS';
export const ALL_USER_TOOLS_BY_ID = 'ALL_USER_TOOLS_BY_ID';
export const CODEREVIEW_DOCUMENTS = 'CODEREVIEW_DOCUMENTS';
export const ALL_CONTINUITY_CLAUSES = 'ALL_CONTINUITY_CLAUSES';
export const ALL_ISO22301_DOCUMENTS = 'ALL_ISO22301_DOCUMENTS';
export const ALL_ADMIN_CONTINUITY_ISO_FORMS = 'ALL_ADMIN_CONTINUITY_ISO_FORMS';
export const ALL_EXEMPTED_CONTROLS = 'ALL_EXEMPTED_CONTROLS';
export const ALL_EXEMPTED_EVIDENCE = 'ALL_EXEMPTED_EVIDENCE';
export const ALL_SECTOR = 'ALL_SECTOR';
export const ALL_CII_TOOLS_ITEMS = 'ALL_CII_TOOLS_ITEMS';
export const ALL_SUBSECTOR_DOCUMENT = 'ALL_SUBSECTOR_DOCUMENT';
export const ALL_ADMIN_CII_FORMS = 'ALL_ADMIN_CII_FORMS';
export const ALL_CII_CONDITIONAL_ITEMS = 'ALL_CII_CONDITIONAL_ITEMS';
export const ALL_CII_CONDITIONAL_ANSWER = 'ALL_CII_CONDITIONAL_ANSWER';
export const ALL_CII_SUMMARY = 'ALL_CII_SUMMARY';
export const ALL_USER_CII_TOOLS = 'ALL_USER_CII_TOOLS';
export const ALL_CII_EXCEPTION = 'ALL_CII_EXCEPTION';
export const ALL_CII_EXCEPTIONS = 'ALL_CII_EXCEPTIONS';
export const ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT = 'ALL_ADMIN_DOCUMENTS_BY_MANAGEMENT';
export const ALL_ADMIN_FORMS_BY_MANAGEMENT = 'ALL_ADMIN_FORMS_BY_MANAGEMENT';

//U S E R S
export const ALL_MERCHANT_USERS = 'ALL_MERCHANT_USERS';
export const ALL_EMPLOYEES = 'ALL_EMPLOYEES';
export const APPROVED_AUDITORS = 'APPROVED_AUDITORS';
export const AUDITOR_POOL_CERTIFICATIONS = 'AUDITOR_POOL_CERTIFICATIONS';
export const AUDITOR_POOL_PORTFOLIOS = 'AUDITOR_POOL_PORTFOLIOS';

// V E N D O R
export const ALL_VENDORS = 'ALL_VENDORS';
export const FILTERED_ALL_VENDORS = 'FILTERED_ALL_VENDORS';
export const ONE_VENDOR = 'ONE_VENDOR';
export const ALL_RESPONSES = 'ALL_RESPONSES';
export const QUESTIONNAIRE_FORMS = 'QUESTIONNAIRE_FORMS';
export const VENDOR_RESPONSES = 'VENDOR_RESPONSES';
export const VENDOR_UPLOADED_QUESTIONNAIRE = 'VENDOR_UPLOADED_QUESTIONNAIRE';
export const ONE_VENDOR_UPLOADED_QUESTIONNAIRE = 'ONE_VENDOR_UPLOADED_QUESTIONNAIRE';
export const ALL_FRAUDGUARDIAN_LIST = 'ALL_FRAUDGUARDIAN_LIST';

// N O T I F I C A T I O N
export const ALL_MERCHANT_NOTIFICATIONS = 'ALL_MERCHANT_NOTIFICATIONS';
export const ADD_MERCHANT_NOTIFICATION = 'ADD_MERCHANT_NOTIFICATION';

// U S E R   G U I D N G
export const SELECT_GUIDE_STEPS = 'SELECT_GUIDE_STEPS';
export const ENABLE_GUIDE_STEPS = 'ENABLE_GUIDE_STEPS';
export const USERGUIDE_INFO = 'USERGUIDE_INFO';

// I N T E G R A T I O N S
export const INTEGRATIONS_GROUPS = 'INTEGRATIONS_GROUPS';
export const MERCHANT_INTEGRATIONS = 'MERCHANT_INTEGRATIONS';
export const ONE_INTEGRATION = 'ONE_INTEGRATION';
export const AUTHORIZATION_URL = 'AUTHORIZATION_URL';

// M E R C H A N T - C O M P L I A N C E
export const ALL_MERCHANT_DOCUMENTS = 'ALL_MERCHANT_DOCUMENTS';
export const ALL_MERCHANT_EVIDENCES = 'ALL_MERCHANT_EVIDENCES';
export const ALL_MERCHANT_DOCUMENTS_BY_TAG = 'ALL_MERCHANT_DOCUMENTS_BY_TAG';
export const ALL_REQUIREMENTS_METRICS = 'ALL_REQUIREMENTS_METRICS';
export const ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS = 'ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS';
export const MERCHANT_DOCUMENT_VERSIONS = 'MERCHANT_DOCUMENT_VERSIONS';
export const MERCHANT_EVIDENCE_VERSIONS = 'MERCHANT_EVIDENCE_VERSIONS';
export const MERCHANT_DOCUMENT_MESSAGES = 'MERCHANT_DOCUMENT_MESSAGES';
export const MERCHANT_ANSWERS = 'MERCHANT_ANSWERS';
export const ALL_CATEGORY_METRICS = 'ALL_CATEGORY_METRICS';
export const ALL_KDPA_CATEGORY_METRICS = 'ALL_KDPA_CATEGORY_METRICS';
export const ALL_GDPA_CATEGORY_METRICS = 'ALL_GDPA_CATEGORY_METRICS';
export const ALL_CONTROL_METRICS = 'ALL_CONTROL_METRICS';
export const ALL_EVIDENCE_METRICS = 'ALL_EVIDENCE_METRICS';
export const ANSWER_HISTORY = 'ANSWER_HISTORY';
export const ALL_EXEMPTED_DOCUMENTS = 'ALL_EXEMPTED_DOCUMENTS';
export const ALL_EXEMPTED_FORMS = 'ALL_EXEMPTED_FORMS';
export const ALL_ISOGROUPS = 'ALL_ISOGROUPS';
export const ALL_ISOGROUPSTWO = 'ALL_ISOGROUPSTWO';
export const ALL_ISOGROUPS_NEW = 'ALL_ISOGROUPS_NEW';
export const ALL_ISOGROUP_METRICS = 'ALL_ISOGROUP_METRICS';
export const ALL_ISOGROUPTWO_METRICS = 'ALL_ISOGROUPTWO_METRICS';
export const ALL_ISOGROUPNEW_METRICS = 'ALL_ISOGROUPNEW_METRICS';
export const ALL_CONTINUITYGROUPS_METRICS = 'ALL_CONTINUITYGROUPS_METRICS';
export const COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD = 'COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD';
export const ALL_NEEDED_DOCS = 'ALL_NEEDED_DOCS';
export const ALL_MERCHANT_DOCS_RESPONSES = 'ALL_MERCHANT_DOC_RESPONSES';
export const ALL_MERCHANT_EXTRA_DOCS = 'ALL_MERCHANT_EXTRA_DOCS';

// A U T O M A T E D  S C A N S
export const ALL_AUTOMATED_SCAN_GROUPS = 'ALL_AUTOMATED_SCAN_GROUPS';
export const ALL_GROUP_AUTOMATED_SCANS = 'ALL_GROUP_AUTOMATED_SCANS';
export const ALL_SCAN_EXCEPTIONS = 'ALL_SCAN_EXCEPTIONS';
export const ALL_EXEMPTED_SCANS = 'ALL_EXEMPTED_SCANS';
export const ALL_GROUP_AUTOMATED_SCANS_ITEM = 'ALL_GROUP_AUTOMATED_SCANS_ITEM';
export const ALL_SCANS = 'ALL_SCANS';
export const SCAN_AI = 'SCAN_AI';
export const ALL_ASSIGNED_TASK = 'ALL_ASSIGNED_TASK';
export const USER_ASSIGNED_TASK = 'USER_ASSIGNED_TASK';

// D A S H B O A R D
export const GET_DASHBOARD_RESULT = 'GET_DASHBOARD_RESULT';
export const GET_AUDITOR_DASHBOARD_RESULT = 'GET_AUDITOR_DASHBOARD_RESULT';
export const GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT = 'GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT';
export const GET_ALL_CISO_SUMMARY = ' GET_ALL_CISO_SUMMARY';
// S M A R T D A S H B O A R D
export const SMART_COMPLIANCE_REPORT = 'SMART_COMPLIANCE_REPORT';
export const SMART_COMPLIANCE_AI_RECOMMENDATIONS = 'SMART_COMPLIANCE_AI_RECOMMENDATIONS';

// P R I C I N G
export const ALL_STANDARDS_PRICES = 'ALL_STANDARDS_PRICES';
export const ALL_PAYMENT_METHODS = 'ALL_PAYMENT_METHODS';
export const ALL_PLAN_REQUESTS = 'ALL_PLAN_REQUESTS';
export const ALL_CUSTOM_PLAN_REQUESTS = 'ALL_CUSTOM_PLAN_REQUESTS';

// S E S S I O N
export const SHOW_SESSION_ENDS_MODAL = 'SHOW_SESSION_ENDS_MODAL';

//PUSH NOTIFICATION
export const USER_DEVICE_TOKEN = 'USER_DEVICE_TOKEN';

// F A Q S
export const ALL_FAQS = 'ALL_FAQS';
export const ALL_GLOSSARY = 'ALL_GLOSSARY';
export const ALL_TESTIMONIALS = 'ALL_TESTIMONIALS';

// A U D I T
export const ALL_AUDIT_CATEGORIES = 'ALL_AUDIT_CATEGORIES';
export const ALL_ACCESSOR_CATEGORIES = 'ALL_ACCESSOR_CATEGORIES';
export const ALL_ACCESSOR_ANSWERS = 'ALL_ACCESSOR_ANSWERS';
export const ALL_AUDIT_ANSWERS = 'ALL_AUDIT_ANSWERS';
export const ALL_AUDIT_REQUESTS = 'ALL_AUDIT_REQUESTS';
export const ALL_ADMIN_AUDITORS = 'ALL_ADMIN_AUDITORS';
export const ALL_QA_REVIEWERS = 'ALL_QA_REVIEWERS';
export const MERCHANT_CERTIFICATE = 'MERCHANT_CERTIFICATE';
export const ALL_MERCHANTS_CERTIFICATE = 'ALL_MERCHANTS_CERTIFICATE';
export const CERTIFICATE_VALIDDATE = 'CERTIFICATE_VALIDDATE';
export const CERTIFICATE_DETAILS = 'CERTIFICATE_DETAILS';

// T R A I N I N G
export const ALL_TRAINING_COURSES = 'ALL_TRAINING_COURSES';
export const ONE_TRAINING_COURSE = 'ONE_TRAINING_COURSE';
export const ALL_TRAINING = 'ALL_TRAINING';
export const ONE_TRAINING = 'ONE_TRAINING';
export const ONE_TRAINEE = 'ONE_TRAINEE';
export const ALL_COURSES_VIDEOS = 'ALL_COURSES_VIDEOS';
export const ALL_COURSE_DOCUMENTS = 'ALL_COURSE_DOCUMENTS';
export const ALL_TRAINEE_COURSE_DOCUMENTS = 'ALL_TRAINEE_COURSE_DOCUMENTS';

//M O D U L E    M O D A L S
export const MODULE_GUIDE = 'MODULE_GUIDE';

//A P I   C O M P L I A N C E
export const API_SCANS = 'API_SCANS';
export const API_SCAN_RESULT_BY_SCAN = 'API_SCAN_RESULT_BY_SCAN';

// T A S K S
export const ALL_ASSIGNED_TASKS = 'ALL_ASSIGNED_TASKS';
export const ALL_ASSIGNED_TASKS_BY_STANDARD = 'ALL_ASSIGNED_TASKS_BY_STANDARD';
//CODE REVIEW
export const SOURCE_CODE = 'SOURCE_CODE';
export const CODE_REVIEW_REPORT_BY_SCAN = 'CODE_REVIEW_REPORT_BY_SCAN';

// IMPLEMNETER
export const REGISTERED_CLIENTS = 'REGISTERED_CLIENTS';
export const CLIENT_ID = 'CLIENT_ID';

// U S E R  G U I D E
export const GO_TO_PREVIOUS_GUIDE = 'GO_TO_PREVIOUS_GUIDE';
export const GO_TO_NEXT_GUIDE = 'GO_TO_NEXT_GUIDE';
export const TOGGLE_USERGUIDE = 'TOGGLE_USERGUIDE';

// CISO REPORT
export const NDPR_CISO_SUMMARY = 'NDPR_CISO_SUMMARY';
export const KDPA_CISO_SUMMARY = 'KDPA_CISO_SUMMARY';
export const SOC2_CISO_SUMMARY = 'SOC2_CISO_SUMMARY';
export const PCIDSS_CISO_SUMMARY = 'PCIDSS_CISO_SUMMARY';
export const PCIDSS4POINT0_CISO_SUMMARY = 'PCIDSS4POINT0_CISO_SUMMARY';
export const ISO_CISO_SUMMARY = 'ISO_CISO_SUMMARY';
export const ISO_TWO_CISO_SUMMARY = 'ISO_TWO_CISO_SUMMARY';
export const TECH_SEC_CISO_SUMMARY = 'TECH_SEC_CISO_SUMMARY';
export const ISO9001_CISO_SUMMARY = 'ISO9001_CISO_SUMMARY';
export const PCISLC_CISO_SUMMARY = 'PCISLC_CISO_SUMMARY';
export const PEOPLE_INFO_SUMMARY = 'PEOPLE_INFO_SUMMARY';
export const PEOPLE_SUMMARY = 'PEOPLE_SUMMARY';
export const PEOPLE_TRAINING_SUMMARY = 'PEOPLE_TRAINING_SUMMARY';
export const GDPA_CISO_SUMMARY = 'GDPA_CISO_SUMMARY';
export const ISO22301_CISO_SUMMARY = 'ISO22301_CISO_SUMMARY';
export const PEOPLE_SCAN_SUMMARY = 'PEOPLE_SCAN_SUMMARY';
export const INVENTORY_SCAN_SUMMARY = 'INVENTORY_SCAN_SUMMARY';
export const SOURCE_CODE_SCAN_SUMMARY = 'SOURCE_CODE_SCAN_SUMMARY';
export const API_SECURITY_SCAN_SUMMARY = 'API_SECURITY_SCAN_SUMMARY';
export const PCI_SCAN_SUMMARY = 'PCI_SCAN_SUMMARY';
export const VUL_SCAN_SUMMARY = 'VUL_SCAN_SUMMARY';
export const ALL_VENDORS_SUMMARY = 'ALL_VENDORS_SUMMARY';
export const ALL_KYC_SUMMARY = 'ALL_KYC_SUMMARY';
export const ALL_FRAUD_SUMMARY = 'ALL_FRAUD_SUMMARY';
export const ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY = 'ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY';
export const ALL_RISK_ASSESSMENTS_SUMMARY = 'ALL_RISK_ASSESSMENTS_SUMMARY';
export const ALL_PENTESTS_SUMMARY = 'ALL_PENTESTS_SUMMARY';
export const ALL_AUTOMATEDSCANS_SUMMARY = 'ALL_AUTOMATEDSCANS_SUMMARY';
export const ALL_THIRD_PARTY_SUMMARY = 'ALL_THIRD_PARTY_SUMMARY';
export const PCISLC_TABLE_CISO_SUMMARY = 'PCISLC_TABLE_CISO_SUMMARY';
export const BLOCKCHAIN_CISO_SUMMARY = 'BLOCKCHAIN_CISO_SUMMARY';
export const CII_CISO_SUMMARY = 'CII_CISO_SUMMARY';
export const BLOCKCHAIN_GENERAL_CISO_SUMMARY = 'BLOCKCHAIN_GENERAL_CISO_SUMMARY';
export const CII_GENERAL_CISO_SUMMARY = 'CII_GENERAL_CISO_SUMMARY';
export const FRAMEWORK_PERCENTAGE_CISO_SUMMARY = 'FRAMEWORK_PERCENTAGE_CISO_SUMMARY';
export const CISO_PERCENTAGE_CISO_SUMMARY = 'CISO_PERCENTAGE_CISO_SUMMARY';
// RISK ASSESSMENT 2.5
export const OVERVIEW = 'OVERVIEW';
export const RISK_ASSESSMENT_THREATS = 'RISK_ASSESSMENT_THREATS';
export const RISK_ASSESSMENT_VULNERABILITIES = 'RISK_ASSESSMENT_VULNERABILITIES';
export const RISK_ASSESSMENT_CATEGORIES = 'RISK_ASSESSMENT_CATEGORIES';
export const RISK_ASSESSMENT_TEMPLATES = 'RISK_ASSESSMENT_TEMPLATES';
export const MERCHANT_ASSET_GROUP = 'MERCHANT_ASSET_GROUP';
export const ALL_MERCHANT_ASSETS = 'ALL_MERCHANT_ASSETS';
export const GET_ALL_MERCHANT_USERS = 'GET_ALL_MERCHANT_USERS';
export const RISK_ASSESSMENT_THREAT_CATEGORIES = 'RISK_ASSESSMENT_THREAT_CATEGORIES';
export const RISK_MANAGEMENT_SETTINGS = 'RISK_MANAGEMENT_SETTINGS';
export const ALL_MERCHANT_RISK_ASSESSMENT = 'ALL_MERCHANT_RISK_ASSESSMENT';
export const GET_RISK_SETTINGS = 'GET_RISK_SETTINGS';
export const GET_ALL_ASSET_OWNER = 'GET_ALL_ASSET_OWNER';
export const GET_ALL_RISK_OWNER = 'GET_ALL_RISK_OWNER';
export const OPEN_RISK_ASSESSMENT = 'OPEN_RISK_ASSESSMENT';
export const OPEN_AUTO_RISK_MITIGATION = 'OPEN_AUTO_RISK_MITIGATION';
export const OPEN_AUTO_RISK_EXCALATION = 'OPEN_AUTO_RISK_EXCALATION';
export const OPEN_AUTO_RISK_APPROVAL = 'OPEN_AUTO_RISK_APPROVAL';
export const ADD_ASSET_RISK = 'ADD_ASSET_RISK';
export const OPEN_AUTO_RISK_ASSESSMENT = 'OPEN_AUTO_RISK_ASSESSMENT';
export const ALL_ADMIN_EVIDENCE_BY_CARD4 = 'ALL_ADMIN_EVIDENCE_BY_CARD4';
